/* 
    Created on : 03.09.2019, 06:18:59
    Author     : Andy Kar
*/


@font-face {
    font-family: 'Circe';
    src: url('./fonts/Circle/Circe-Light.eot');
    src: local('Circe Light'), local('Circe-Light'),
        url('./fonts/Circle/Circe-Light.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Circle/Circe-Light.woff2') format('woff2'),
        url('./fonts/Circle/Circe-Light.woff') format('woff'),
        url('./fonts/Circle/Circe-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Circe Extra';
    src: url('./fonts/Circle/Circe-ExtraBold.eot');
    src: local('Circe Extra Bold'), local('Circe-ExtraBold'),
        url('./fonts/Circle/Circe-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Circle/Circe-ExtraBold.woff2') format('woff2'),
        url('./fonts/Circle/Circe-ExtraBold.woff') format('woff'),
        url('./fonts/Circle/Circe-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Circe';
    src: url('./fonts/Circle/Circe-Thin.eot');
    src: local('Circe Thin'), local('Circe-Thin'),
        url('./fonts/Circle/Circe-Thin.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Circle/Circe-Thin.woff2') format('woff2'),
        url('./fonts/Circle/Circe-Thin.woff') format('woff'),
        url('./fonts/Circle/Circe-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Circe';
    src: url('./fonts/Circle/Circe-Regular.eot');
    src: local('Circe'), local('Circe-Regular'),
        url('./fonts/Circle/Circe-Regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Circle/Circe-Regular.woff2') format('woff2'),
        url('./fonts/Circle/Circe-Regular.woff') format('woff'),
        url('./fonts/Circle/Circe-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Circe';
    src: url('./fonts/Circle/Circe-Bold.eot');
    src: local('Circe Bold'), local('Circe-Bold'),
        url('./fonts/Circle/Circe-Bold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/Circle/Circe-Bold.woff2') format('woff2'),
        url('./fonts/Circle/Circe-Bold.woff') format('woff'),
        url('./fonts/Circle/Circe-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: "Roboto";
    src: url("./fonts/Roboto/Roboto-Regular.ttf");
    src: local('Roboto');
}
@font-face {
    font-family: "Roboto Bold";
    src: url("./fonts/Roboto/Roboto-Bold.ttf");
    src: local('Roboto Bold'), local('Roboto-Bold');
}
@font-face {
    font-family: "Roboto Medium";
    src: url("./fonts/Roboto/Roboto-Medium.ttf");
    src: local('Roboto Medium'), local('Roboto-Medium');
}
@font-face {
    font-family: "Roboto Thin";
    src: url("./fonts/Roboto/Roboto-Thin.ttf");
    src: local('Roboto Thin'), local('Roboto-Thin');
}
@font-face {
    font-family: "Roboto Light";
    src: url("./fonts/Roboto/Roboto-Light.ttf");
    src: local('Roboto Light'), local('Roboto-Light');
}

