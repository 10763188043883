/*header*/
.header{
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 20px 0px rgba(156, 156, 156, 0.1);
  height: 120px;
  line-height: 120px;
}
.logo{
  max-width: 300px;
  white-space: nowrap;
}
.phone_and_icons a svg{
  height: 20px;
}
.logo_link{
  display: inline-block;
  max-width: 150px;
  margin-right: 15px;
  line-height: 1;
  vertical-align: middle;

  img{
    width: 100%;
    //transform: translateY(3px);
  }
}
.slogan{
  padding-left: 15px;
  border-left: 1px solid #dddddd;
  line-height: 1.2;
  vertical-align: middle;
  display: inline-block;
  white-space: normal;
  width: calc(100% - 135px);
  font-size: 12px;
  position: relative;
  top: 6px;
}
.phone_and_icons{
  width: calc(100% - 300px);
  text-align: right;
}
.phone{
  font-size: 16px;
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
  margin-right: 55px;
}
.icon{
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 1;
  vertical-align: middle;
}
.icon_user{
  position: relative;
  width: 15px;
}
.phone_and_icons .icon{
  margin-left: 25px;
}
.icon_cart{
  position: relative;
}
.counter_in_cart{
  position: absolute;
  top: -5px;
  right: -15px;
  font-size: 12px;
  width: 20px;
  height: 20px;
  background-color: #479436;
  border-radius: 50%;
  display: block;
  text-align: center;
  line-height: 22px;
  color: white;
  font-family: "Circe";
}
/*header*/
@media screen and (max-width: 1170px){
  .phone_and_icons .icon {
    margin-left: 15px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 940px){
  .foot_logo_link{
    margin: 0 auto;
    max-width: 105px;
  }
  .footer .foot_item{
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
  .sub_title_block,
  .footer .dflex{
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 768px){
  .header{
    height: 60px;
    line-height: 60px;
  }
  .header .logo{
    width: 100px;
  }
  .phone_and_icons{
    width: calc(100% - 100px);
  }
  .header .phone,
  .header .slogan{
    display: none;
  }
  .phone_and_icons .icon{
    margin-left: 5px;
    margin-right: 10px;
  }
}