/* 
    Created on : 10.12.2019, 06:18:59
    Author     : Andy Kar
*/

#account-account{
	form{
		width: 100%;
	}
	
	.fieldset{
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		text-align: left;
		
		.form-group{
			max-width: 370px;
			width: 100%;
			margin-bottom: 26px;
			
			&.required label:after{
				content: '*';
				color: $red;
			}	
		}
	}
	
	@media screen and (max-width: 767px){
		.form-group{
			min-width: 100%;
			
			input{
				text-align: left!important;
				padding: 0 5px 0 15px!important;
			}
		}
	}
}
