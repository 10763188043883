/*
    Created on : 9 февр. 2020 г., 10:31:34
    Author     : Andy Kar
*/
@import "../../_variables";

#order-info-table-wrap{

  a{
    text-decoration: none;
    border: none;
  }

  .order-title-wrap{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    h2{
      line-height: 44px;
      text-transform: none;
    }
  }

  #order-info-table{
    background: #FBFCFB;
    border-radius: 5px;
    padding: 0 40px;
    margin-bottom: 30px;

    .order-info-table{
      margin-bottom: 25px;

      .tbody, .theader{
        .tr, .th{
          box-sizing: border-box;
          width: 690px;
          max-width: 100%;
          min-height: 72px;
          padding: 20px 0;
          border-bottom: 1px solid #E9E9E9;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;

          .table-cell{
            flex: 1 1 auto;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .order-info-title{
              font-family: Circe, sans-serif;
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              line-height: 22px;
              color: $grey;
              min-width: 145px;
              max-width: 145px;
              padding-right: 10px;
            }

            span{
              font-family: 'Circe', sans-serif;
              font-style: normal;
              font-weight: normal;
              color: $text;
              font-size: 14px;
              line-height: 22px;
              text-align: left;
              width: 100%;

            }

            &.order-cell{
              width: 160px;

              .order_id{
                font-weight: bold;
                font-size: 14px;
                line-height: 22px;
                color: $green;
                text-align: left;
              }
            }

            &.date-cell{
              width: 200px;
            }

            &.payment-method-cell{
              width: 200px;
            }

            &.shipping-method-cell{
              width: 130px;
            }
          }
        }
      }

      .theader{
        .th{
          .table-cell{
            span{
              font-weight: bold!important;
              font-size: 14px!important;
              line-height: 22px!important;
              color: $grey!important;
              text-decoration: none!important;
            }
          }
        }
      }
    }

    .address-info-wrap{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;

      .address-info{
        width: 100%;
        max-width: 330px;

        p{
          font-family: 'Circe', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
          margin-bottom: 10px;
        }

        .address-title{
          font-weight: bold;
          color: $grey;
        }

        .address-value{
          color: $text;
          line-height: 20px;
          margin-bottom: 28px;
        }
      }
    }
  }

  #product-table{

    .product-table{
      margin-bottom: 25px;

      .tbody, .theader{
        .tr, .th{
          box-sizing: border-box;
          width: 770px;
          max-width: 100%;
          min-height: 132px;
          padding: 20px 0;
          border-bottom: 1px solid #E9E9E9;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: center;

          .table-cell{

            .order-info-title{
              font-family: Circe, sans-serif;
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              line-height: 22px;
              color: $grey;
              min-width: 145px;
              max-width: 145px;
              padding-right: 10px;
            }

            span{
              font-family: 'Circe', sans-serif;
              font-style: normal;
              font-weight: normal;
              color: $text;
              font-size: 14px;
              line-height: 22px;
              text-align: left;
              width: 100%;

            }

            &.image-cell {
              width: 95px;
            }

            &.name-cell{
              width: 200px;

              a{
                width: 185px;
                display: inline-block;
                font-family: 'Circe', sans-serif;
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 22px;
                color: $text;
              }
            }

            &.model-cell{
              width: 110px;
            }

            &.quantity-cell{
              width: 95px;
            }

            &.price-cell{
              width: 100px;

              .price{
                font-weight: bold;
              }
            }

            &.total-cell{
              width: 100px;

              .total{
                font-weight: bold;
                color: $green;
              }
            }

            &.cart-cell{
              width: 70px;

              .to-cart{
                padding: 0!important;
                margin: 0;
                width: 70px;
                height: 30px;
                border: 1px solid $lite-grey;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
      }

      .theader{
        .th{
          min-height: 62px;

          .table-cell{
            span{
              font-weight: bold!important;
              font-size: 14px!important;
              line-height: 22px!important;
              color: $grey!important;
              text-decoration: none!important;
            }

            &.name-cell{
              width: 295px;
            }
          }
        }
      }
    }
  }

  .btn-add{
    margin-top: 20px;
  }
}

@media screen and (max-width: 767px){
  #order-info-table-wrap{
    #order-info-table{
      padding: 0;
      margin: 0;

      .order-info-table{
        margin: 0;

        .tbody .tr{
          padding: 20px 0 0;
          border-bottom: none;

          .table-cell{
            width: 100%!important;

            span{
              margin-bottom: 10px;
              max-width: calc(100% - 145px);
            }
          }
        }
      }

      .address-info{
        width: 100%!important;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        max-width: 100%!important;

        .order-info-title{
          font-family: Circe, sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 22px;
          color: $grey;
          min-width: 145px;
          max-width: 145px;
          padding-right: 10px;
        }

        p{
          margin-bottom: 10px;
          max-width: calc(100% - 145px);
          margin-bottom: 0;
        }
      }
    }

    #product-table .product-table .tbody .tr{
      .table-cell:not(.image-cell){
        width: 100%!important;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 10px;

        .order-info-title{
          font-family: Circe, sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 22px;
          color: $grey;
          min-width: 145px;
          max-width: 145px;
          padding-right: 10px;
        }

        &.name-cell{
          width: auto!important;
          max-width: calc(100% - 95px)!important;

          a{
            width: auto;
          }
        }

        &.cart-cell{
          margin-top: 10px;

          button{
            width: auto;
            border-color: $green;

            .order-info-title{
              width: 100%;
              text-align: center;
              color: $green;
              padding: 0;
            }
          }
        }

        span{
          margin-bottom: 10px;
          max-width: calc(100% - 145px);
          margin-bottom: 0;
        }
      }
    }
  }
}

