/*
Created on : 02.02.2020, 19:10:23
Author     : Andy Kar
*/
@import "../../_variables";

#account-account{
	padding: 70px 0!important;

	#main_content #content-wrap #content {
		.auth {
			.fieldset {
				max-width: 300px;
				margin: 0 auto;

				&.warning {
					text-align: center;
					color: #dd0000;
					line-height: 1.3;
					font-size: 13px;
				}

				&.message {
					text-align: center;
					color: $green;
					line-height: 1.3;
					font-size: 16px;
					margin-bottom: 20px;
				}

				&.register {
					max-width: 100%;
				}

				.reset-button{
					padding: 5px 30px 5px 0 !important;
					border: none;
					margin-bottom: 0;
				}

				.send-button {
					margin: 20px auto;
					min-width: 200px;
					font-size: 18px;
					border-radius: 8px;

					&:hover {
						color: #fff;
						background: #4f9e34;
					}
				}
			}

			#login_form {
				legend {
					text-align: center;
				}

				.loginOptions {
					width: 100%;
					display: flex;
					justify-content: center;

					.login-option-button {
						width: 90px;
						height: 90px;
						display: flex;
						flex-wrap: wrap;
						align-items: center;
						justify-content: center;
						padding: 5px !important;
						line-height: 1;
						border-radius: 8px;
						margin: 10px 5px 30px;

						&.active {
							color: #fff;
							background: #4f9e34;
						}

						svg {
							font-size: 30px;
							margin-bottom: 5px;
						}
					}
				}
			}
		}
	}
}


