/* 
    Created on : 05.02.2020, 14:52:46
    Author     : Andy Kar
*/
@import "../../_variables";

.pagination-wrap{
	padding: 30px 0;
	width: 100%;
	
	.pagination{
		letter-spacing: -5px;
		list-style: outside none none;
		padding: 0;
		
		li{
			letter-spacing: normal;
			display: inline-block;
			border: 0 none;
			font: inherit;
			margin: 0;
			padding: 0;
			vertical-align: baseline;
			
			span{
				border: none;
				min-width: 30px;
				text-align: center;
				font: inherit;
				margin: 0;
			}
		}
	

		& li > a, 
		& li > span {
			font-family: Noto Sans, Arial, sans-serif;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 22px;
			display: block;
			width: 60px;
			height: 20px;
			padding: 0;
			color: #9D9D9D;
			background-color: transparent;
			border-right: 1px solid #DADADA;
			border-radius: 0;
			cursor: pointer;

			&.pagi-next{
				border: none;
			}

			&.pagi-next, &.pagi-prev{
				position: relative;
				display: block;

				&:after{
					content: url(../../image/arrow_long.svg);
					display: inline-block;
					width: 37px;
					height: 20px;

				}
			}

			&.pagi-prev{
				&:after{
					transform: rotate(180deg) translateY(-3px);
				}
			}

			&.pagi-last, &.pagi-first{
				display: none;
			}

		}

		& .active > a,
		& > .active > span,
		& > .active > a:focus,
		& > .active > span:focus {
			color: $green;
			cursor: default;
			background-color: transparent;
		}

		& > li > a:hover,
		& > li > span:hover{
			color: $green;
			background-color: transparent;
		}

		& > .active > a:hover,
		& > .active > span:hover{
			color: $green;
		}
	}
}