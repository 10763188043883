img{
  max-width: 100%;
}

input, textarea, select, button{
  font-family: "Circe";
}

*{
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  outline: none !important;
}
a{
  color: #479436;
  text-decoration: none;
  transition: all .3s;
}
strong{
  font-family: "Circe-Bold";
}
html, body {
  height: 100%;
}
body {
  display: flex;
  flex-direction: column;
}

.footer {
  flex-shrink: 0;
  height: auto;
}


.dflex{
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.wrapper:not(.wrapper--page-common-home){
  max-width: 1200px;
  padding: 0 15px;
  width: 100%;
  margin: 0 auto;
}






/*content*/
//.page_title{
//  font-size: 30px;
//  font-family: "Circe";
//  margin-bottom: 30px;
//}
//.left_side{
//  padding-top: 30px;
//  width: calc(100% - 340px);
//  padding-right: 60px;
//}
//.right_side{
//  width: 340px;
//  background-color: rgb(252, 253, 252);
//  box-shadow: 0px 0px 6px 0px rgba(156, 156, 156, 0.2);
//}
//.page_container{
//  align-items: flex-start;
//}
//.right_side .block{
//  padding: 30px;
//  border-bottom: 1px solid #ecedec;
//}
//.block_title{
//  font-size: 20px;
//  font-family: "Circe";
//  margin-bottom: 30px;
//  position: relative;
//}
//.block_content{
//  padding-top: 30px;
//  border-top: 2px solid #f5f5f5;
//}
//.right_block_title_text{
//  position: absolute;
//  right: 0;
//  top: 50%;
//  transform: translateY(-50%);
//  color: #c61d41;
//  font-size: 14px;
//  white-space: nowrap;
//  font-family: "Circe";
//}
//.product_item{
//  padding: 0 15px;
//  border-right: 1px solid #f5f5f5;
//  text-decoration: none;
//  display: block;
//  text-align: center;
//  cursor: pointer;
//}
//.product_image{
//  display: block;
//  padding: 20px 0;
//}
//.product_title{
//  display: block;
//  font-size: 12px;
//  padding-bottom: 15px;
//}
//a.product_item .product_title{
//  min-height: 71px;
//}
//.product_item .product_image img{
//  width: auto;
//  height: 160px;
//  object-fit: contain;
//}
//.owl-item.active + .owl-item.active + .owl-item.active + .owl-item.active .product_item{
//  border-right: 0;
//}
//
//.owl-carousel .owl-nav button.owl-next,
//.owl-carousel .owl-nav button.owl-prev{
//  width: 30px;
//  height: 30px;
//  position: absolute;
//  top: 50%;
//  transform: translateY(-50%) rotate(180deg);
//  left: -30px;
//  border-radius: 50%;
//  border: 1px solid #dedede !important;
//}
//.owl-carousel .owl-nav button.owl-next{
//  left: auto;
//  right: -30px;
//  transform: translateY(-50%) rotate(0deg);
//}
//.owl-nav svg{
//  width: 7px;
//  display: inline-block;
//  vertical-align: middle;
//  line-height: 1;
//  position: absolute;
//  top: 50%;
//  left: 50%;
//  transform: translateY(-50%) translateX(-50%);
//}
//.owl-nav svg path{
//  fill: #dedede;
//}
//.link_with_icon svg{
//  width: 14px;
//  display: inline-block;
//  vertical-align: middle;
//  margin-right: 6px;
//}
//.link_with_icon svg path{
//  fill: #479436 !important;
//}
//.link_text{
//  display: inline-block;
//  vertical-align: middle;
//}
//.block_foot{
//  padding-top: 25px;
//}
//.left_side .block{
//  margin-bottom: 70px;
//}
//.product_image_line{
//  width: 100px;
//  position: relative;
//  padding-left: 30px;
//  text-align: center;
//}
//.icon_remove{
//  width: 20px;
//  height: 20px;
//  font-size: 20px;
//  display: block;
//  position: absolute;
//  left: 0;
//  top: 50%;
//  transform: translateY(-50%) rotate(45deg);
//  border-radius: 50%;
//  border: 1px solid red;
//  color: red;
//  line-height: 22px;
//  text-align: center;
//  font-family: "Circe";
//}
//.product_right_side{
//  width: calc(100% - 100px);
//  padding-left: 20px;
//  align-items: center;
//}
//.product_cart_line{
//  border-bottom: 1px solid #f5f5f5;
//  padding-top: 35px;
//  padding-bottom: 35px;
//  align-items: center;
//}
//.addFav_wrapper_line{
//  width: 30px;
//  /*padding-top: 3px;*/
//}
//.product_cart_line .product_title{
//  width: calc(100% - 350px);
//}
//.product_other_text_line{
//  width: 320px;
//  padding-left: 20px;
//  padding-right: 50px;
//  align-items: center;
//}
//.block_cart .block_content{
//  padding-top: 0;
//}
//.product_cart_line .product_title{
//  font-family: "Circe";
//  font-size: 14px;
//  padding-right: 20px;
//}
//.icon_add_favorite{
//  width: 30px;
//  height: 30px;
//  border: 1px solid #479436;
//  border-radius: 50%;
//  line-height: 28px;
//  text-align: center;
//  transition: all .3s;
//}
//.icon_add_favorite svg{
//  display: inline-block;
//  width: 15px;
//  vertical-align: middle;
//  line-height: 1;
//}
//.icon_add_favorite path{
//  fill: #479436;
//  transition: all .3s;
//}
//.icon_add_favorite.active_fav,
//.icon_add_favorite:hover{
//  border-color: #569d47;
//}
//.icon_add_favorite.active_fav path,
//.icon_add_favorite:hover path{
//  fill: #569d47;
//}
//.no_stock_info_text{
//  font-size: 12px;
//  padding-left: 15px;
//  border-left: 2px solid #479436;
//}
//.quantity_wrap{
//  height: 36px;
//  border-radius: 18px;
//  padding: 5px;
//  background-color: #f8fbf8;
//  position: relative;
//}
//.quantity_info{
//  text-align: center;
//  font-size: 12px;
//  padding-top: 5px;
//}
//.product_quantity{
//  position: relative;
//}
//.quantity_info{
//  position: absolute;
//  left: 50%;
//  top: 100%;
//  transform: translateX(-50%);
//  white-space: nowrap;
//  font-weight: bold;
//  color: red;
//}
//.quantity_wrap input{
//  width: 68px;
//  background-color: transparent;
//  border: 0;
//  outline: none;
//  text-align: center;
//  font-size: 16px;
//  color: #c61d41;
//  font-family: "Circe";
//  opacity: 1 !important;
//}
//.quantity_wrap input:disabled{
//  opacity: 1 !important;
//}
//.quantity_wrap .icon{
//  width: 26px;
//  height: 26px;
//  cursor: pointer;
//  border: 1px solid #479436;
//  position: relative;
//  border-radius: 50%;
//}
//.icon_minus:before{
//  position: absolute;
//  content: "";
//  display: block;
//  height: 1px;
//  left: 6px;
//  right: 6px;
//  top: 50%;
//  transform: translateY(-50%);
//  background-color: #479436;
//}
//.icon_plus:after,
//.icon_plus:before{
//  position: absolute;
//  content: "";
//  display: block;
//  height: 1px;
//  left: 6px;
//  right: 6px;
//  top: 50%;
//  transform: translateY(-50%);
//  background-color: #479436;
//}
//.icon_plus:after{
//  transform: translateY(-50%) rotate(90deg);
//}
//.quantity_wrap .icon:hover:before,
//.quantity_wrap .icon:hover:after{
//  background-color: #4b973b;
//}
//.price_line_per_one{
//  font-size: 12px;
//  padding-top: 10px;
//  display: block;
//}
//.product_quantity{
//  width: 130px;
//}
//.product_line_price{
//  width: calc(100% - 130px);
//  text-align: right;
//}
//.product_line_all_price{
//  font-size: 20px;
//  font-family: "Circe";
//  padding-top: 5px;
//  display: block;
//}
//.price_currency{
//  font-family: "Circe";
//}
//.block_content > .product_item:last-child{
//  border-right: 0;
//}
//.product_title{
//  color: #343434;
//}
//.product_item:hover{
//  opacity: 1;
//}
//.product_item:hover .product_title{
//  color: #479436;
//}
//.price_item_text{
//  width: calc(100% - 30px);
//  text-align: left;
//  color: #343434;
//}
//.icon_add_to_cart{
//  width: 30px;
//  height: 30px;
//  border-radius: 50%;
//  border: 1px solid #dfdfdf;
//  line-height: 28px;
//  text-align: center;
//}
//.icon_add_to_cart svg{
//  display: inline-block;
//  width: 16px;
//  vertical-align: middle;
//}
//.icon_add_to_cart svg path{
//  fill: #dfdfdf;
//}
//.icon_add_to_cart:hover svg path{
//  fill: #479436;
//}
//.block_icon{
//  width: 65px;
//}
//.block_icon svg path{
//  fill: #479436;
//}
//.block_line_content{
//  width: calc(100% - 235px);
//  padding-left: 25px;
//  padding-right: 25px;
//}
//.block_collect_batteries{
//  padding: 35px 30px 35px 70px;
//  background-color: white;
//  box-shadow: 0px 0px 6px 0px rgba(156, 156, 156, 0.2);
//  margin-bottom: 0;
//}
//.left_side .block.block_collect_batteries{
//  margin-bottom: 0;
//}
//.block_content > .product_cart_line:last-child{
//  border-bottom: 0;
//}
//.inner_block_title strong{
//  font-size: 20px;
//  font-family: "Circe-Bold";
//  display: inline-block;
//  vertical-align: middle;
//}
//.icon_tooltop{
//  display: inline-block;
//  vertical-align: middle;
//  margin-left: 10px;
//  width: 12px;
//  height: 12px;
//}
//.icon_tooltop i{
//  vertical-align: top;
//}
//.icon_tooltop path{
//  fill: #b7b7b7;
//}
//.block_inner_description{
//  margin-top: 10px;
//}
//.block_action{
//  text-align: center;
//  width: 170px;
//  padding-left: 20px;
//  border-left: 1px solid #eeeeee;
//}
//.block_action_info{
//  display: inline-block;
//  text-align: center;
//  margin-bottom: 10px;
//}
//.btn{
//  display: inline-block;
//  padding: 14px 20px 12px;
//  border-radius: 20px;
//  border: 1px solid transparent;
//  text-transform: uppercase;
//  font-family: "Circe-Bold";
//  font-size: 12px;
//  color: #479436;
//  cursor: pointer;
//  transition: all .3s;
//  text-align: center;
//  background-color: transparent;
//}
//.button,
//.btn_red_border{
//  border-color: #479436;
//  color: #479436;
//}
//.block_action .btn{
//  width: 100%;
//}
//.btn_transparent{
//  background-color: transparent;
//}
//.btn:hover,
//.btn_transparent:hover{
//  opacity: 1;
//  background-color: #479436;
//  color: white;
//  border: 1px solid #479436;
//}
//.block_to_free_shipping{
//  font-family: "Circe-Bold";
//}
//.red_text{
//  color: #479436;
//}
//.line_progress{
//  height: 4px;
//  width: 100%;
//  background-color: #e9e9e9;
//  border-radius: 2px;
//  margin-top: 10px;
//}
//.line_progress span{
//  display: block;
//  transition: all .3s;
//  background-color: #479436;
//  height: 100%;
//  border-radius: 2px;
//}
//.block_delivery_info .item_delivery{
//  margin-bottom: 20px;
//}
//.delivery_icon{
//  width: 36px;
//}
//.delivery_icon svg{
//  max-width: 24px;
//}
//.delivery_description{
//  width: calc(100% - 36px);
//}
//.delivery_description strong{
//  display: block;
//}
//.block_delivery_info > .item_delivery:last-child{
//  margin-bottom: 0;
//}
//.right_side .block_title{
//  font-size: 14px;
//  margin-bottom: 5px;
//}
//.selectize-control.single .item{
//  padding-right: 30px;
//}
//.selectize-control.single .selectize-input,
//select,
//input[type="text"]:not(.quantity_input_text),
//input[type="email"],
//input[type="tel"],
//input[type="password"]{
//  width: auto;
//  border-radius: 5px;
//  border: 1px solid #e9e9e9;
//  /*color: #bcbcbc;*/
//  height: 35px;
//  line-height: 33px;
//  padding: 0 12px;
//  background-color: white;
//  display: inline-block;
//  max-width: 100%;
//  background-image: none;
//  box-shadow: none;
//}
//input::placeholder{
//  color: #bcbcbc;
//}
//.block_promokod input{
//  width: 100%;
//  border-radius: 5px;
//  border: 1px solid #e9e9e9;
//  /*color: #bcbcbc;*/
//  height: 35px;
//  line-height: 33px;
//  padding: 0 12px;
//  background-color: white;
//}
//.icon_ok_right{
//  position: relative;
//}
//.icon_ok_right .icon_right_check{
//  display: block;
//  content: "";
//  width: 33px;
//  height: 33px;
//  line-height: 33px;
//  text-align: center;
//  color: #cbcbcb;
//  position: absolute;
//  right: 1px;
//  top: 1px;
//  border-left: 1px solid #e9e9e9;
//  background-color: white;
//  border-top-right-radius: 5px;
//  border-bottom-right-radius: 5px;
//  background-repeat: no-repeat;
//  background-position: center center;
//  font-size: 12px;
//}
//.total_title{
//  width: 55%;
//  text-align: left;
//  font-family: "Circe";
//}
//.total_value{
//  width: 45%;
//  text-align: right;
//}
//.total_seller{
//  position: relative;
//  /*cursor: pointer;*/
//}
//.hidden_total_value{
//  position: absolute;
//  top: calc(100% + 15px);
//  left: -30px;
//  right: -30px;
//  background-color: white;
//  transform: scale(0);
//  max-height: 0;
//  transition: all .3s;
//  padding: 30px;
//  box-shadow: 0px -1px 15px 0px rgba(156, 156, 156, 0.15);
//  background-color: #fefefe;
//  transform-origin: top center;
//  z-index: 20;
//}
//.hidden_total_value:before{
//  position: absolute;
//  display: block;
//  content: "";
//  border-bottom: 9px solid #fefefe;
//  border-top: 9px solid transparent;
//  border-left: 10px solid transparent;
//  border-right: 10px solid transparent;
//  bottom: 100%;
//  left: 45px;
//}
//.hidden_total_value > .total_line:last-child{
//  margin-bottom: 0;
//}
//
//.showAllTotals .hidden_total_value{
//  max-height: 400px;
//  transform: scale(1);
//}
//.total_line{
//  margin-bottom: 15px;
//}
//.pre_cart_total > .total_line:last-child{
//  margin-bottom: 0;
//}
//.all_total_cart .total_title{
//  font-size: 20px;
//  font-family: "Circe-Bold";
//}
//.all_total_cart .total_value{
//  font-size: 20px;
//  font-family: "Circe-Bold";
//  color: #4f9e34;
//}
//.all_total_cart{
//  /*margin-bottom: 25px;*/
//}
//.total_reward{
//  /*text-align: center;*/
//  margin-bottom: 10px;
//}
//.block_total_wrap .btn{
//  width: 100%;
//}
//input.btn{
//  padding-bottom: 14px;
//}
//.pr100{
//  padding-right: 100px;
//}
//.width60{
//  width: 60%;
//}
//.width40{
//  width: 40%;
//}
//.width55{
//  width: 55%;
//}
//.width45{
//  width: 45%;
//}
//.mb35{
//  margin-bottom: 35px;
//}
//.fz16{
//  font-size: 16px;
//}
//.icon_block_info{
//  width: 40px;
//}
//.icon_block_info svg{
//  width: 24px;
//}
//.icon_block_info path{
//  fill: #479436;
//}
//.block_info_content{
//  width: calc(100% - 40px);
//}
//.block_info{
//  align-items: flex-start;
//}
//.mini_block_title{
//  font-size: 16px;
//  font-family: "Circe-Bold";
//  margin-bottom: 20px;
//}
//.info_item{
//  margin-bottom: 15px;
//}
//.block_info_content .total_value{
//  text-align: left;
//}
//.block_soc_full{
//  padding: 40px 75px;
//  background-image: url(/image/img/bg_soc.jpg);
//  border-radius: 6px;
//  box-shadow: 0px 0px 6px 0px rgba(156, 156, 156, 0.15);
//  margin-bottom: 18px;
//  background-size: cover;
//  background-position: center center;
//}
//.block_soc_full > strong{
//  display: block;
//  font-size: 30px;
//  color: #656565;
//  margin-bottom: 10px;
//}
//.block_soc_full > span{
//  font-size: 16px;
//  font-family: "Roboto Medium";
//  margin-bottom: 10px;
//  display: block;
//}
//.icon_soc{
//  width: 38px;
//  height: 38px;
//  border: 1px solid #dedede;
//  border-radius: 50%;
//  line-height: 36px;
//  text-align: center;
//}
//.icon_soc{
//  margin-right: 20px;
//}
//.icon_soc path{
//  fill: #479436;
//}
//.icon_soc svg{
//  width: 18px;
//  display: inline-block;
//  vertical-align: middle;
//  line-height: 1;
//}
//.icon_soc:hover{
//  background-color: #479436;
//  border-color: #479436;
//  opacity: 1;
//}
//.icon_soc:hover path{
//  fill: white;
//}
//.no_bg{
//  background-color: transparent;
//}
//
//.two_col_soc .item_soc{
//  width: 50%;
//  padding-right: 10px;
//  text-align: center;
//}
//.two_col_soc > .item_soc:last-child{
//  padding-right: 0;
//  padding-left: 10px;
//}
//.no_shadow{
//  box-shadow: none;
//}
//
//.two_col_soc .item_soc strong{
//  display: block;
//  font-size: 20px;
//  font-family: "Circe-Bold";
//  color: #656565;
//}
//.two_col_soc .item_soc span{
//  font-size: 16px;
//  display: block;
//  margin-bottom: 10px;
//}
//.two_col_soc .item_soc .btn{
//  width: 100%;
//}
//.review_reward_bg{
//  background-image: url(/image/img/bg_review.jpg);
//  background-repeat: no-repeat;
//  background-size: cover;
//  background-position: center center;
//  padding: 65px 25px 25px 25px;
//  border-radius: 6px;
//  overflow: hidden;
//  box-shadow: 0px 0px 6px 0px rgba(156, 156, 156, 0.15);
//}
//.welcome_seller_bg{
//  background-image: url(/image/img/bg_invite.jpg);
//  background-repeat: no-repeat;
//  background-size: cover;
//  background-position: center center;
//  padding: 65px 25px 25px 25px;
//  border-radius: 6px;
//  overflow: hidden;
//  box-shadow: 0px 0px 6px 0px rgba(156, 156, 156, 0.15);
//}
//.nice_block_title{
//  font-size: 30px;
//  font-family: "Circe-Bold";
//  margin-bottom: 30px;
//  line-height: 1;
//  text-transform: uppercase;
//  position: relative;
//  z-index: 10;
//  max-width: calc(100% - 300px);
//}
//.nice_block_title span{
//  display: inline-block;
//  position: relative;
//}
//.nice_block_title span:after{
//  position: absolute;
//  content: "";
//  left: 0;
//  bottom: -32px;
//  height: 2px;
//  right: 0;
//  background-color: #9ec495;
//}
//.carousel_blog.owl-carousel .owl-nav button.owl-next,
//.carousel_blog.owl-carousel .owl-nav button.owl-prev{
//  position: relative;
//  transform: none;
//  top: auto;
//}
//.carousel_blog.owl-carousel .owl-nav button.owl-prev{
//  transform: rotate(180deg);
//}
//.carousel_blog .owl-nav{
//  position: absolute;
//  top: -60px;
//  right: 0;
//}
//.carousel_blog .owl-nav button{
//  margin-left: 25px;
//}
//.owl-nav button:hover path{
//  fill: #85b87a;
//}
//.blog_item{
//  border-radius: 6px;
//  overflow: hidden;
//  box-shadow: 0px 0px 6px 0px rgba(156, 156, 156, 0.2);
//}
//.blog_image{
//  position: relative;
//  border-bottom-left-radius: 30px;
//  overflow: hidden;
//  border-bottom-right-radius: 30px;
//}
//.blog_time{
//  position: absolute;
//  left: 0;
//  top: 0;
//  height: 26px;
//  line-height: 26px;
//  font-family: "Roboto Medium";
//  color: #343434;
//  padding: 0 42px 0 26px;
//  border-bottom-right-radius: 26px;
//  background-color: rgba(255,255,255,.8);
//  z-index: 60;
//}
//.blog_description{
//  padding: 20px 25px;
//}
//.carousel_blog .owl-stage-outer{
//  margin-left: -15px;
//  margin-right: -15px;
//  padding: 0 15px 15px;
//}
//.blog_title{
//  min-height: 75px;
//}
//.blog_title a{
//  font-size: 16px;
//  font-family: "Roboto Medium";
//  display: block;
//  color: #343434;
//}
//.blog_short_text{
//  padding-top: 0;
//  margin-top: 0;
//  position: relative;
//  max-height: 0;
//  opacity: 0;
//  transform: scale(0);
//  transition: all .3s;
//}
//.blog_short_text:before{
//  position: absolute;
//  display: block;
//  content: "";
//  top: 0;
//  left: 0;
//  height: 2px;
//  width: 50px;
//  background-color: #479436;
//  transition: all .3s;
//}
//.blog_item:hover .blog_short_text{
//  transform: scale(1);
//  padding-top: 20px;
//  margin-top: 20px;
//  max-height: 400px;
//  opacity: 1;
//}
//.blog_item{
//  position: relative;
//  height: 300px;
//  width: 100%;
//}
//.blog_inner_wrap{
//  position: absolute;
//  bottom: 0;
//  left: 0;
//  right: 0;
//}
//.blog_image img,
//.blog_image a{
//  display: block;
//  height: 200px;
//  object-fit: cover;
//}
///*content*/
//
//
//.checkout__input-box{
//  display: flex;
//  flex-wrap: wrap;
//  align-items: center;
//  margin-bottom: 35px;
//}
///*checkout*/
//.checkout__input-box-title,
//.sub_block_label{
//  width: 135px;
//  padding-right: 0;
//  font-size: 13px;
//  /*white-space: nowrap;*/
//  text-transform: none;
//}
//.simplecheckout-rule-group{
//  justify-content: right;
//  display: flex;
//  padding-left: 145px;
//  padding-top: 5px;
//  color: #c61d41;
//  width: 100%;
//  flex-wrap: wrap;
//}
//.checkout__input-box-title + input{
//  max-width: 360px;
//}
//.checkout__input-box-title + input,
//.sub_block_fields{
//  width: calc(100% - 135px);
//}
//.sub_block_row{
//  margin-bottom: 35px;
//  flex-wrap: wrap;
//  align-items: center;
//}
//.sub_block_radio input[type="radio"]{
//  display: none;
//}
//.like_a_radio{
//  /*background-image: url(/image/img/icon_validate.png);*/
//  width: 24px;
//  height: 24px;
//  line-height: 22px;
//  text-align: center;
//  background-repeat: no-repeat;
//  background-position: center center;
//  display: inline-block;
//  vertical-align: middle;
//  border: 1px solid #eeeeee;
//  border-radius: 50%;
//  transition: all .3s;
//  color: #dddddd;
//}
//.like_a_radio i{
//  font-size: 14px;
//  position: relative;
//  top: 1px;
//  left: 1px;
//}
//input:checked + .like_a_radio{
//  color: #479436;
//}
//input:checked ~ .like_a_label_inside{
//  opacity: 1;
//}
//label.sub_title_block{
//  cursor: pointer;
//}
//.sub_title_block{
//  display: flex;
//  align-items: center;
//  padding-top: 20px;
//  padding-bottom: 20px;
//}
//.sub_block_radio,
//.sub_block_title_text{
//  width: calc(50% - 50px);
//}
//.sub_block_radio{
//  white-space: nowrap;
//}
//.like_a_label_inside{
//  display: inline-block;
//  vertical-align: middle;
//  padding-left: 15px;
//  font-family: "Circe-Bold";
//  opacity: .5;
//  transition: all .3s;
//  white-space: normal;
//  font-size: 16px;
//}
//.sub_block{
//  position: relative;
//}
//.block_slogan{
//  font-size: 16px;
//  font-family: "Circe-Bold";
//  margin-bottom: 30px;
//}
//.sub_block:after{
//  position: absolute;
//  display: block;
//  content: "";
//  right: 0;
//  bottom: 0;
//  left: 0;
//  height: 1px;
//  background: #f1f1f1;
//}
//* > .sub_block:last-child:after{
//  display: none;
//}
//.input_wrapper_little{
//  width: 118px;
//}
//.selectize-control.single{
//  letter-spacing: 0;
//}
//.input_wrapper_little.address_collection_korpus,
//.input_wrapper_little.address_collection_flat{
//  width: 33px;
//  padding-left: 0;
//  padding-right: 0;
//}
//.input_wrapper_little.address_collection_korpus input,
//.input_wrapper_little.address_collection_flat input{
//  padding-left: 5px;
//  padding-right: 5px;
//}
//.input_wrapper_170{
//  width: 170px;
//}
//.address_collection_fields > div{
//  margin-right: 9px;
//}
//.address_collection_fields > div:last-child{
//  margin-right: 0;
//}
//.address_collection_fields{
//  flex-wrap: wrap;
//  justify-content: left;
//}
//.hidden_inportant_hipping_block{
//  display: none !important;
//  opacity: 0;
//  height: 0;
//  width: 0;
//  transform: scale(0);
//  overflow: hidden;
//}
//.hidden_sub_item_block{
//  padding-bottom: 0;
//  padding-top: 0;
//  max-height: 0;
//  transform: scale(0);
//  opacity: 0;
//  /*transition: all .3s;*/
//  /*overflow: hidden;*/
//  position: relative;
//  z-index: 10;
//}
//.opened_collapse_block .hidden_sub_item_block{
//  padding-top: 20px;
//  padding-bottom: 20px;
//  max-height: 5000px;
//  transform: scale(1);
//  opacity: 1;
//}
//.radio_true_false_wrapper{
//  display: inline-block;
//  justify-content: flex-start;
//}
//.radio_true_false_wrapper input[type="radio"]{
//  display: none;
//}
//.radio_true_false_wrapper > input[type="radio"] + label,
//.radio_true_false_wrapper > label{
//  width: 50% !important;
//  padding: 10px 40px;
//  border: 1px solid #dedede;
//  font-family: "Circe";
//  color: #343434;
//  cursor: pointer;
//  opacity: .5;
//  transition: all .5s;
//  text-align: center;
//}
//
//.radio_true_false_wrapper > input[type="radio"] + label:after,
//.radio_true_false_wrapper > input[type="radio"] + label:before{
//  display: none;
//}
//.radio_true_false_wrapper > label:hover{
//  opacity: 1;
//  position: relative;
//  z-index: 5;
//}
//.radio_true_false_wrapper > input:first-child + label{
//  border-top-left-radius: 17px;
//  border-bottom-left-radius: 17px;
//}
//.radio_true_false_wrapper > label:last-child{
//  border-top-right-radius: 17px;
//  border-bottom-right-radius: 17px;
//  margin-left: -1px;
//}
//.radio_true_false_wrapper > input:checked + label{
//  border-color: #479436;
//  color: #479436;
//  opacity: 1;
//  position: relative;
//  z-index: 5;
//}
//.sub_block_title_price > *{
//  display: inline-block;
//}
//.sub_block_title_price{
//  color: #4f9e34;
//  white-space: nowrap;
//  width: 100px;
//  text-align: right;
//}
//.js_promokod_block_entry + .you_have_reward{
//  margin-top: 25px;
//}
//.you_have_reward{
//  margin-bottom: 20px;
//}
//.icon_mini{
//  display: inline-block;
//  vertical-align: middle;
//}
//.icon_before .icon_mini{
//  margin-right: 6px;
//}
//.icon_up_carret{
//  position: relative;
//  display: inline-block;
//  vertical-align: middle;
//  border-top: 5px solid rgba(84,84,84,.5);
//  border-left: 5px solid transparent;
//  border-right: 5px solid transparent;
//  border-bottom: 5px solid transparent;
//  width: 10px;
//  height: 10px;
//  top: 2.5px;
//  margin-left: 5px;
//  transition: all .3s;
//}
//.showAllTotals .icon_up_carret{
//  transform: rotate(180deg);
//  top: -4px;
//}
//.link_with_icon{
//  font-size: 0;
//}
//.link_with_icon img,
//.link_with_icon svg{
//  width: 15px;
//}
//.link_with_icon i{
//  margin-right: 5px;
//  color: #343434;
//}
//.link_with_icon .link_text{
//  font-size: 14px;
//  width: calc(100% - 24px);
//}
//.add_order_comment a .link_text,
//.add_order_comment a{
//  font-size: 12px;
//}
//.no_call{
//  font-size: 12px;
//}
//.like_a_checkbox{
//  position: relative;
//  width: 24px;
//  height: 24px;
//  border-radius: 5px;
//  border: 1px solid #e9e9e9;
//  display: inline-block;
//  vertical-align: middle;
//  background-repeat: no-repeat;
//  background-position: center center;
//  transition: all .3s;
//}
//.custom_checkbox{
//  font-size: 0;
//}
//.custom_checkbox > *{
//  display: inline-block;
//  vertical-align: middle;
//  font-size: 12px;
//}
//.like_a_label{
//  width: calc(100% - 24px);
//  padding-left: 12px;
//}
//.custom_checkbox > input{
//  display: none;
//}
//.custom_checkbox > input:checked + .like_a_checkbox{
//  background-image: url(/image/img/radio_checked.png);
//}
//.agreement_order{
//  font-size: 11px;
//  margin-top: 12px;
//}
//.agreement_order a{
//  color: #343434;
//  text-decoration: underline;
//}
//.block_contact_info .input_wrapper input{
//  max-width: 360px;
//  width: 100%;
//}
//.hidden_radio{
//  display: none;
//}
//.titles_tabs{
//  margin-bottom: 35px;
//}
//.selectdelivery_tarifi{
//  /*display: none;*/
//}
//.contents_tab{
//  /*margin-top: 60px;*/
//}
//.item_delivery_tarif{
//  width: 25%;
//  text-align: center;
//  flex: 1;
//  cursor: pointer;
//}
//.block_delivery_tarid_wrapper{
//  width: calc(100% - 7.5px);
//  display: inline-block;
//  text-align: left;
//  border: 1px solid #eeeeee;
//  border-radius: 6px;
//  cursor: pointer;
//  height: 100%;
//}
//.selectdelivery_tarifi{
//  align-items: stretch;
//  flex-direction: row;
//}
//.selectdelivery_tarifi > .item_delivery_tarif:first-child{
//  text-align: left;
//}
//.selectdelivery_tarifi > .item_delivery_tarif:last-child{
//  text-align: right;
//}
//.delivery_tarif_description{
//  padding: 9.5px 10px;
//}
//.delivery_tarif_description strong,
//.delivery_tarif_description span:not(.icon){
//  opacity: .5;
//}
//.active_tab_title .delivery_tarif_description strong,
//.active_tab_title .delivery_tarif_description span:not(.icon){
//  opacity: 1;
//  color: #479436;
//}
//.delivery_tarif_price{
//  padding: 10px;
//  border-top: 1px solid #eeeeee;
//  font-size: 12px;
//}
//.delivery_tarif_description strong{
//  font-size: 16px;
//  font-family: "Roboto Medium";
//  display: inline-block;
//  vertical-align: middle;
//  max-width: calc(100% - 20px);
//  padding-right: 5px;
//  margin-bottom: 0;
//}
//.delivery_tarif_description span:not(.icon){
//  display: block;
//  font-family: "Roboto Medium";
//  font-size: 12px;
//}
//.delivery_tarif_description i.icon{
//  margin-left: 0;
//  display: inline-block;
//  vertical-align: middle;
//}
//.delivery_tarif_description{
//  font-size: 0;
//}
//.tab_content{
//  display: none;
//}
//.active_tab_content{
//  display: block;
//}
//.active_tab_title .block_delivery_tarid_wrapper{
//  border-color: #479436;
//}
//.active_tab_title .delivery_tarif_description{
//  color: #479436;
//}
//.underline_text{
//  text-decoration: underline;
//  color: #343434;
//}
//.border_left_text p{
//  margin-bottom: 10px;
//}
//.border_left_text{
//  padding-left: 30px;
//  margin-left: 10px;
//  border-left: 2px solid #479436;
//  line-height: 21px;
//}
//.payment_card_image{
//  display: block;
//  width: 100%;
//  margin-bottom: 15px;
//}
//.payment_card_image img{
//  display: block;
//  width: 100%;
//  height: 90px;
//  object-fit: contain;
//}
//.list_of_payments_cards{
//  justify-content: space-between;
//}
//.list_of_payments_cards .item_delivery_tarif{
//  width: 25%;
//  text-align: center;
//}
//.wrapper_card_select{
//  display: inline-block;
//  text-align: center;
//  width: calc(100% - 50px);
//}
//.list_of_payments_cards{
//  margin-top: 35px;
//}
//
//.payment_card_title{
//  font-size: 16px;
//}
//.payment_card_title,
//.payment_card_slogan{
//  font-family: "Roboto Medium";
//  display: block;
//  transition: all .3s;
//}
//.payment_card_slogan,
//.payment_card_title{
//  opacity: .5;
//}
//.active_tab_title .payment_card_slogan,
//.active_tab_title .payment_card_title{
//  opacity: 1;
//}
//.payment_card_slogan{
//  font-size: 12px;
//}
//.active_tab_title .payment_card_title,
//.active_tab_title .payment_card_slogan{
//  color: #479436;
//}
//input[type="text"].date_input{
//  /*max-width: 370px;*/
//  width: 100%;
//  font-size: 13px;
//}
//.date_input_wrap{
//  position: relative;
//  display: inline-block;
//  /*max-width: 370px;*/
//  width: 100%;
//}
//.date_input_wrap:after{
//  display: block;
//  content: "";
//  width: 33px;
//  height: 33px;
//  position: absolute;
//  right: 1px;
//  top: 1px;
//  border-left: 1px solid #e9e9e9;
//  background-color: white;
//  border-top-right-radius: 5px;
//  border-bottom-right-radius: 5px;
//  background-image: url(/image/img/icon_calendar.png);
//  background-repeat: no-repeat;
//  background-position: center center;
//  cursor: pointer;
//}
//.black_link{
//  color: #343434;
//}
//.left_side .block.block_checkout_paymenst{
//  margin-bottom: 0;
//}
//.block_delivery_tarid_wrapper{
//  position: relative;
//  padding-bottom: 50px;
//}
//.delivery_tarif_price{
//  min-height: 50px;
//  bottom: 0;
//  left: 0;
//  right: 0;
//  position: absolute;
//}
//.custom_true_select_wrapper{
//  display: inline-block;
//  position: relative;
//  background: white;
//}
//.right_side input[type="text"]{
//  width: 100%;
//}
//.selectize-control.single{
//  height: 35px;
//}
//.selectize-control.single .selectize-input:after,
//.custom_true_select_wrapper .like_a_arrow{
//  display: block;
//  content: "";
//  width: 33px;
//  height: 30px;
//  position: absolute;
//  right: 1px;
//  top: 1px;
//  background-color: white;
//  border-radius: 5px;
//  background-image: url(/image/images/arrow_light.svg);
//  background-repeat: no-repeat;
//  background-position: center center;
//  background-color: white;
//  transform: rotate(90deg);
//  background-size: 8px;
//
//}
//.selectize-control.single .item{
//  color: #545454;
//  cursor: pointer;
//}
//.xdsoft_timepicker.active{
//  display: none !important;
//}
//.required_star{
//  color: #c61d41;
//}
//.fa-info{
//  width: 12px;
//  height: 12px;
//  border-radius: 50%;
//  color: white;
//  background-color: #b7b7b7;
//  font-size: 8px;
//  text-align: center;
//  line-height: 12px !important;
//}
//.right_side{
//  position: relative;
//  right: 0;
//  bottom: 0;
//  /*transition: bottom .5s;*/
//}
//.right_side:not(.fixed_side){
//  right: 0 !important;
//}
//.fixed_side{
//  position: fixed;
//  z-index: 900;
//  margin-bottom: -20px;
//}
//.fixed_side_bottom{
//  /*margin-bottom: 0;*/
//}
//.dflex.page_container{
//  justify-content: flex-start;
//}
//.fixed_side_bottom{
//
//}
//.all_bonus_checkout{
//  display: none;
//}
//.row-customer_confirm_password,
//.row-customer_password,
//.row-customer_register{
//  display: none;
//}
//.block.block_total_wrap{
//  padding-bottom: 0;
//  border-bottom: 0;
//}
//.simplecheckout-button-block{
//  padding-left: 30px;
//  padding-bottom: 30px;
//  padding-right: 30px;
//}
//.simplecheckout-button-block .btn{
//  width: 100%;
//}
//.simplecheckout-cart-buttons{
//  text-align: center;
//  margin-bottom: 10px;
//}
//
//#simplecheckout_shipping_address{
//  display: none;
//}
//.product_cart_line .quantity_info{
//  display: none;
//}
//.product_cart_line.no_more_products_left .quantity_info{
//  display: block;
//}
//.product_cart_line .product_title{
//  padding-bottom: 0;
//}
//.js_geihelper_list{
//  position: relative;
//}
//.geohelper_list{
//  position: absolute;
//  left: 0;
//  top: 100%;
//  background-color: white;
//  list-style: none;
//  z-index: 99999;
//  max-height: 150px;
//  overflow: auto;
//  box-shadow: 0px 0px 6px 0px rgba(156, 156, 156, 0.2);
//  border-radius: 10px;
//}
//.geohelper_list li{
//  cursor: pointer;
//  padding: 8px 15px;
//  opacity: .7;
//  transition: all .3s;
//}
//.geohelper_list li:hover{
//  opacity: 1;
//}
//#temp_popup_container{
//  position: fixed;
//  top: 50%;
//  left: 50%;
//  background-color: rgba(255,255,255,.6);
//  transform: translateX(-50%) translateY(-50%);
//  transition: all .3s;
//  background: #fcfdfc;
//  padding: 35px 25px 25px;
//  box-shadow: 0px 0px 6px 0px rgba(156, 156, 156, 0.2);
//  z-index: 5001;
//}
//#temp_popup_container .form-group{
//  display: flex;
//  flex-wrap: wrap;
//  align-items: center;
//  margin-bottom: 20px;
//}
//#temp_popup_container .form-group label{
//  width: 100px;
//  text-align: right;
//  padding-right: 10px;
//}
//#temp_popup_container .form-group input{
//  width: calc(100% - 100px);
//}
//#temp_popup_container .form-group a{
//  text-align: right;
//  margin-top: 10px;
//  width: 100%;
//}
//#temp_popup_container .button{
//  width: 100%;
//}
//#simple_login_header img{
//  position: absolute;
//  display: block;
//  width: 100%;
//  height: 100%;
//  opacity: 0;
//  top: 0;
//}
//#simple_login_header{
//  position: absolute;
//  right: 5px;
//  top: 5px;
//  width: 15px;
//  height: 15px;
//  line-height: 15px;
//  text-align: center;
//  font-size: 28px;
//  opacity: .3;
//  font-weight: 300;
//}
//#simple_login_header:before{
//  display: inline-block;
//  content: "+";
//  transform: rotate(45deg);
//}
//.hidden_required_field{
//  max-height: 0;
//  transition: all .3s;
//  /*overflow: hidden;*/
//  opacity: 0;
//  margin-bottom: 0;
//}
//.hidden_required_field.visible_required_inputs{
//  max-height: 800px;
//  opacity: 1;
//  margin-bottom: 35px;
//}
//.simplecheckout .content{
//  margin-bottom: 20px;
//  text-align: center;
//}
//.mb0{
//  margin-bottom: 0 !important
//}
//a[data-onclick="openLoginBox"]{
//  display: none;
//}
//.nofix_static_sidebar{
//  position: static !important;
//}
//.input_wrapper{
//  position: relative;
//}
//.like_a_placeholder{
//  font-size: 12px;
//  color: #9a9a9a;
//  position: absolute;
//  top: 0;
//  left: 0;
//  right: 0;
//  padding: 2px 12px;
//  white-space: nowrap;
//  opacity: 0;
//  transition: all .3s;
//}
//.like_a_placeholder.show_placeholder{
//  top: 100%;
//  opacity: 1;
//}
//.mirror_animate {
//  animation-name: blinker;
//  animation-iteration-count: infinite;
//  animation-timing-function: cubic-bezier(1.0,0,0,1.0);
//  animation-duration: .5s;
//  -webkit-animation-name: blinker;
//  -webkit-animation-iteration-count: infinite;
//  -webkit-animation-timing-function: cubic-bezier(1.0,0,0,1.0);
//  -webkit-animation-duration: .5s;
//}
//@keyframes blinker {
//  from { opacity: 1; }
//  to { opacity: 0; }
//}
//
//@-webkit-keyframes blinker {
//  from { opacity: 1; }
//  to { opacity: 0; }
//}
///*.no_more_products_left .quantity_wrap,*/
//.no_more_products_left .product_line_price,
//.no_more_products_left .product_title,
//.no_more_products_left .product_image_line{
//  opacity: .2;
//}
//.link_product_wrapper{
//  display: block;
//}
//.product_title_mobile{
//  display: none;
//}
//.green_text{
//  color: #4f9e34;
//}
//.product_line_all_price{
//  position: relative;
//}
//.old_price_total{
//  position: absolute;
//  font-size: 12px;
//  right: 0;
//  bottom: 100%;
//  font-weight: normal;
//  font-style: italic;
//}
//.old_price_total:after{
//  position: absolute;
//  content: "";
//  display: block;
//  height: 2px;
//  width: 100%;
//  left: 50%;
//  top: 50%;
//  transform: translateX(-50%) translateY(-50%) rotate(20deg);
//  background-color: #4f9e34;
//  transform-origin: center center;
//}
//.sale_basket_product .all_total_basket_line{
//  color: #4f9e34;
//}
//.product_cart_line .show_stock_quantity .quantity_info{
//  display: block;
//}
//.disable_icon_number{
//  opacity: .3;
//}
//.text_about_customer_exist{
//  text-align: center;
//  margin-bottom: 25px;
//  font-size: 16px;
//}
//div#simplecheckout_comment_replika {
//  margin: 0;
//  height: 0;
//  padding: 0;
//  box-shadow: 0px 0px 6px 0px rgba(156, 156, 156, 0.2);
//  transform-origin: top center;
//  transform: scale(0);
//  opacity: 0;
//  transition: all .5s;
//}
//div#simplecheckout_comment_replika.show_comment_form{
//  transform: scale(1);
//  opacity: 1;
//  margin-top: 15px;
//  margin-bottom: 15px;
//  padding: 15px;
//  height: 100px;
//}
//#simplecheckout_comment_replika textarea{
//  resize: none;
//  width: 100%;
//  height: 70px;
//  border-radius: 5px;
//  border: 0;
//  line-height: 33px;
//  padding: 0;
//  background-color: white;
//  display: inline-block;
//  max-width: 100%;
//  background-image: none;
//  box-shadow: none;
//  line-height: 1.4;
//  font-family: 'Roboto';
//  background-color: transparent;
//}
//#simplecheckout_comment_replika .checkout-heading.panel-heading{
//  font-size: 0;
//  margin-bottom: 5px;
//  width: 15px;
//  height: 15px;
//  position: absolute;
//  right: 5px;
//  top: 5px;
//  cursor: pointer;
//  line-height: 15px;
//  text-align: center;
//  opacity: .5;
//}
//#simplecheckout_comment_replika .checkout-heading.panel-heading:before{
//  content: "+";
//  display: block;
//  transform: rotate(45deg);
//  font-size: 15px;
//  opacity: .5;
//}
//
//
//
//
//.popup_shadow{
//  position: fixed;
//  top: 0;
//  left: 0;
//  right: 0;
//  bottom: 0;
//  background-color: rgba(0,0,0,.3);
//  display: none !important; /*tmp main.css*/
//  z-index: 4999;
//}
//.popup{
//  position: fixed !important;
//  top: 50%;
//  left: 50%;
//  transform: scale(0) translateX(-50%) translateY(-50%);
//  background-color: white;
//  padding: 22px;
//  opacity: 0;
//  transition: all .3s;
//  max-width: 768px;
//  width: 100%;
//  z-index: 5000;
//  transform-origin: top left;
//  box-shadow: 0px 0px 6px 0px rgba(156, 156, 156, 0.2);
//  display: block;
//  margin: 0;
//}
//.popup_gateway{
//  max-width: 340px;
//  padding: 10px;
//}
//.popup_shadow.show_popup{
//  display: block !important;
//}
//.popup.show_popup{
//  transform: scale(1) translateX(-50%) translateY(-50%);
//  opacity: 1;
//}
//.mobile_add_to_cart{
//  display: none;
//}
//.visible_link{
//  display: inline !important;
//}
//.close_popup{
//  font-size: 0;
//  margin-bottom: 0;
//  width: 20px;
//  height: 20px;
//  position: absolute;
//  right: 15px;
//  top: 15px;
//  cursor: pointer;
//  line-height: 15px;
//  text-align: center;
//  color: #C1C1C1;
//  opacity: .4;
//  transform: rotate(45deg);
//  font-size: 30px;
//  z-index: 10;
//}
//.mb20{
//  margin-bottom: 20px;
//}
//.alert.alert-warning{
//  padding: 20px;
//  border: 2px solid #4f9e34;
//  text-align: center;
//}
///*checkout*/



///*NEW LOGIN FORM*/
//body.show_popup{
//  overflow: hidden;
//}
//.hidden_form_form{
//  display: none;
//}
//.hidden_form_row{
//  display: none !important;
//}
//.shadow{
//  z-index: 99999998;
//}
//.popup_new_login{
//  max-width: 500px;
//  padding: 60px 65px;
//  z-index: 99999999;
//}
//.popup_new_login .form_row input{
//  font-size: 26px;
//}
//.popup_new_login .form_row input:not(.no_top_margin){
//  margin-top: 15px;
//}
//.popup_new_login .form_row button,
//.popup_new_login .form_row input{
//  height: 50px;
//  line-height: 48px;
//}
//input[name="new_login_phone"]{
//  border-top-right-radius: 0;
//  border-bottom-right-radius: 0;
//}
//.green_btn_bg,
//.popup_new_login .form_row button.green_btn_bg,
//.popup_new_login .form_row input + button{
//  width: 100%;
//  line-height: 1;
//  padding: 0 20px;
//  margin-top: 20px;
//  background-color: #4f9e33;
//  height: 55px;
//  line-height: 53px;
//  border-radius: 28px;
//  color: white;
//}
//.green_btn_bg:hover,
//.popup_new_login .form_row button.green_btn_bg:hover,
//.popup_new_login .form_row input + button:hover{
//  background-color: white;
//  color: #4f9e33;
//}
//.lh1vam{
//  line-height: 1;
//}
//.popup_new_login .form_row input[name="new_login_phone"]{
//  width: 100%;
//}
//.error_field{
//  border-color: red !important;
//}
//.popup_new_login .form_row input.new_code_item{
//  text-align: center;
//  width: 77.5px;
//}
//.jcsb{
//  justify-content: space-between;
//}
//.new_login_response{
//  text-align: center;
//}
//.popup .form_row{
//  flex-wrap: wrap;
//}
//.fullwidthbtn{
//  display: block;
//  margin: 15px auto 0;
//  max-width: 340px;
//  width: 100%;
//  border-radius: 5px;
//}
//.product_weight_in_cart{
//  opacity: .5;
//  font-size: 11px;
//  display: block;
//}
//.errorField{
//  border-color: red !important;
//}
//.alert_eco_checkout{
//  text-align: center;
//  color: red;
//  text-transform: uppercase;
//}
//.alert_eco_checkout p{
//  margin-bottom: 15px;
//}
//.total_reward{
//  margin-top: 15px;
//  margin-bottom: 15px;
//}
//.link_product_wrapper{
//}
//.simple_login#temp_popup_container{
//  display: none !important;
//  visibility: hidden;
//  opacity: 0;
//}
//
//
//.block_popup_title{
//  font-family: Circe;
//  font-style: normal;
//  font-weight: bold;
//  font-size: 30px;
//  line-height: 44px;
//  color: #343434;
//  margin-bottom: 25px;
//}
//.reset_pass_wrapper_link{
//  text-align: right;
//}
//
//.switch_login_form a{
//  font-family: Circe;
//  font-style: normal;
//  font-weight: bold;
//  font-size: 16px;
//  line-height: 24px;
//  color: #343434;
//  text-decoration: none;
//  display: inline-block;
//  margin-right: 30px;
//  position: relative;
//  padding-bottom: 5px;
//}
//.switch_login_form a:after{
//  display: block;
//  content: "";
//  height: 2px;
//  background-color: #4F9E34;
//  position: absolute;
//  left: 0;
//  right: 100%;
//  transition: all .3s;
//  bottom: -3px;
//}
//.switch_login_form a.active_form_menu:after{
//  right: 0;
//}
//.switch_login_form{
//  border-bottom: 2px solid #E9E9E9;
//  text-align: left;
//  margin-bottom: 20px;
//}
//.width100{
//  display: block !important;
//  width: 100% !important;
//}
//.mb15{
//  margin-bottom: 15px;
//}
//.mb20{
//  margin-bottom: 20px;
//}
//.js_switch_to_reset{
//  font-family: Circe;
//  font-style: normal;
//  font-weight: bold;
//  font-size: 13px;
//  line-height: 19px;
//  text-decoration-line: underline;
//  text-transform: uppercase;
//  color: #999999;
//}
//.hidden_form_row__OLDS{
//  margin-top: 50px;
//}
//.fzDefault{
//  font-size: 14px !important;
//}
//.new_login_response{
//  text-align: left;
//  margin-bottom: 13px;
//  font-family: Circe;
//  font-style: normal;
//  font-weight: normal;
//  font-size: 16px;
//  line-height: 24px;
//  color: #343434;
//}
//.new_login_response.not_empty{
//  margin-top: -20px;
//}
//.text_center{
//  text-align: center;
//}
//.error_text{
//  font-size: 26px;
//  color: red;
//  text-transform: uppercase;
//}
//.checkout__input-box-title.required:before{
//  display: none;
//}
//@media screen and (max-width: 560px){
//  .popup{
//    padding: 20px;
//  }
//  .popup_new_login .form_row input.new_code_item{
//    width: 65px;
//  }
//  form:not(.iframe_form_login) .block_popup_title{
//    font-size: 22px;
//  }
//}
///*NEW LOGIN FORM*/
//
//
//
///*tmp main.css*/
//input[type="text"], input[type="password"], input[type="email"], input[type="tel"], input[type="search"], textarea, select, .input{
//  font-family: "Circe";
//  text-transform: none;
//  font-size: 12px;
//  letter-spacing: 0;
//}
//a:hover{
//  border: 0;
//  border-color: transparent;
//}
//.checkout__input-box{
//  padding: 0;
//}
//input[type="text"].disabled, input[type="text"][disabled="disabled"], input[type="text"][disabled], input[type="password"].disabled, input[type="password"][disabled="disabled"], input[type="password"][disabled], input[type="email"].disabled, input[type="email"][disabled="disabled"], input[type="email"][disabled], input[type="tel"].disabled, input[type="tel"][disabled="disabled"], input[type="tel"][disabled], input[type="search"].disabled, input[type="search"][disabled="disabled"], input[type="search"][disabled], textarea.disabled, textarea[disabled="disabled"], textarea[disabled], select.disabled, select[disabled="disabled"], select[disabled], .input.disabled, .input[disabled="disabled"], .input[disabled]{
//  background-color: transparent;
//}
//.block_get_more_in_cart{
//  display: block;
//}
//.products-list__status {
//  display: block;
//  font-size: 12px;
//  margin-bottom: 8px;
//  float: right;
//  padding: 0!important;
//  line-height: 16px;
//  margin-bottom: 0;
//}
//.catalogue__product-name {
//  font-size: 12px;
//  width: 100%;
//}
//.products-list__name {
//  max-height: none!important;
//  padding-bottom: 10px;
//  line-height: 1.2;
//}
//.product__reviews {
//  height: 15px;
//  color: #a99665;
//  display: block;
//  position: relative;
//  font-size: 9px;
//  float: right;
//  margin-top: -20px;
//  line-height: 15px;
//}
//.product__rating-fill {
//  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZjMjI0IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci1zdGFyIiB4PSIwcHgiIHk9IjBweCI+PHBvbHlnb24gZmlsbD0iI2ZmYzIyNCIgcG9pbnRzPSIxMiAyIDE1LjA5IDguMjYgMjIgOS4yNyAxNyAxNC4xNCAxOC4xOCAyMS4wMiAxMiAxNy43NyA1LjgyIDIxLjAyIDcgMTQuMTQgMiA5LjI3IDguOTEgOC4yNiAxMiAyIi8+PC9zdmc+);
//}
//.product__rating {
//  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZjMjI0IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci1zdGFyIiB4PSIwcHgiIHk9IjBweCI+PHBvbHlnb24gcG9pbnRzPSIxMiAyIDE1LjA5IDguMjYgMjIgOS4yNyAxNyAxNC4xNCAxOC4xOCAyMS4wMiAxMiAxNy43NyA1LjgyIDIxLjAyIDcgMTQuMTQgMiA5LjI3IDguOTEgOC4yNiAxMiAyIi8+PC9zdmc+);
//}
//.products-list__link {
//  /* height: 330px; */
//  height: 100% !important;
//}
//.catalogue__product-name span {
//  text-transform: none;
//  color: #343434;
//}
//.products-list__btn{
//  background-color: #479436;
//}
//.products-list__btn:hover{
//  background-color: #90B544;
//}
//.checkout__input-box-title{
//  padding-bottom: 0;
//}
//.checkout__input-row{
//  margin: 0;
//}
//.link_product_wrapper{
//  border: 0;
//}
//.icon_add_favorite:hover{
//  border: 1px solid #479436;
//}
//.icon_remove:hover{
//  border: 1px solid red;
//}
//.add_order_comment{
//  margin-bottom: 15px;
//  margin-top: 15px;
//}
//.compact_right_side{
//  margin-bottom: 0;
//}
//.btn_loader{
//  position: relative;
//  overflow: hidden;
//  color: transparent !important;
//}
//.btn_loader:after{
//  display: block;
//  position: absolute;
//  background-color: rgba(255,255,255,.5);
//  background-image: url('/catalog/view/theme/theme_lightshop/img/icons/svg/loader.svg');
//  background-repeat: no-repeat;
//  background-position: center center;
//  content: "";
//  top: 0;
//  left: 0;
//  right: 0;
//  bottom: 0;
//}
//::placeholder{
//  opacity: .5;
//}
//.sub_block_title_text{
//  font-family: 'Circe';
//}
//.compact_right_side .delivery_pickup{
//  display: none !important;
//}
//.compact_right_side.right_side .block{
//  padding: 20px 30px;
//}
//.compact_right_side.right_side .total_line{
//  margin-bottom: 5px;
//}
//.no_link_link{
//  cursor: default;
//}
//#simplecheckout_customer .checkout__input-row{
//  display: flex;
//  flex-wrap: wrap;
//}
//#simplecheckout_customer .checkout__input-row > .checkout__input-box{
//  width: 50%;
//}
//#simplecheckout_customer .checkout__input-box-title,
//#simplecheckout_customer .sub_block_label{
//  width: 100%;
//}
//#simplecheckout_customer .checkout__input-row > .checkout__input-box:first-child,
//#simplecheckout_customer .checkout__input-row > .checkout__input-box:nth-child(2n + 1){
//  padding-right: 15px;
//}
//#simplecheckout_customer .checkout__input-row > .checkout__input-box:nth-child(2n){
//  padding-left: 15px;
//}
//#simplecheckout_customer .checkout__input-row > .checkout__input-box input{
//  width: 100%;
//  max-width: 100%;
//}
//.js_insert_address_select{
//  display: flex;
//  flex-wrap: wrap;
//}
//.noChecked{
//  order: 1;
//}
//.checkedClass{
//  order: 0;
//}
//.sub_block_row.mbmini .sub_block_fields{
//  position: relative;
//  z-index: 90;
//  min-height: 37px;
//}
//.js_insert_address_select,
//.js_row_address{
//  width: 100%;
//  justify-content: flex-start;
//}
//.sub_block_row.mbmini{
//  position: relative;
//  z-index: 500;
//}
//.js_insert_address_select.js_opened_address_list {
//  position: absolute;
//  z-index: 999;
//  right: 0;
//  left: 0;
//}
//.pr15{
//  padding-right: 15px;
//}
//.pl15{
//  padding-left: 15px;
//}
//.with50{
//  width: 50%;
//}
//.js_address_menu{
//  justify-content: flex-start;
//  align-items: center;
//}
//.js_address_menu > *{
//  display: flex;
//  align-items: center;
//}
//.js_address_menu .custom_radio_wrapper{
//  margin-right: 35px;
//}
//.text_with_icon{
//  margin-left: 7px;
//}
//.like_a_input{
//  width: auto;
//  border-radius: 5px;
//  /*border: 1px solid #e9e9e9;*/
//  height: 35px;
//  line-height: 33px;
//  padding: 0 12px;
//  background-color: white;
//  display: inline-block;
//  max-width: 100%;
//}
//.new_link_with_icon{
//  border: 0;
//}
//.js_row_address{
//  /*margin-bottom: 15px;*/
//  height: 35px;
//}
//.js_row_address .like_a_input{
//  overflow: hidden;
//  white-space: nowrap;
//  width: 100%;
//  font-size: 13px;
//}
//.address_collection_fields{
//  transition: all .3s;
//}
//.address_collection_fields.hidden_add_address_row{
//  max-height: 0;
//  overflow: hidden;
//  opacity: 0;
//  transform: scale(0);
//  transition: all .3s;
//  margin-top: 0;
//}
//.address_collection_fields{
//  max-height: 500px;
//  transform: scale(1);
//  opacity: 1;
//  /*margin-top: 10px;*/
//}
//.mt15_when_opened{
//  margin-top: 10px;
//}
//.sub_block_row.mbmini{
//  margin-bottom: 0;
//}
//.visible_required_inputs{
//  position: relative;
//  z-index: 100;
//}
//.js_btn_add_address .like_a_input{
//  cursor: pointer;
//}
//.noChecked{
//  max-height: 0;
//  transform: scale(0);
//  overflow: hidden;
//  opacity: 0;
//  transition: max-height .3s;
//}
//.js_opened_address_list .noChecked{
//  max-height: 35px;
//  transform: scale(1);
//  opacity: 1;
//}
//.js_insert_address_select{
//  position: relative;
//  cursor: pointer;
//  border: 1px solid #e9e9e9;
//  border-radius: 5px;
//}
//.js_insert_address_select:before{
//  position: absolute;
//  display: block;
//  content: "";
//  height: 100%;
//  width: 100%;
//  z-index: 10;
//}
//.js_insert_address_select:after{
//  display: block;
//  content: "";
//  position: absolute;
//  right: 10px;
//  top: 13px;
//  border-left: 2px solid #479436;
//  border-bottom: 2px solid #479436;
//  transform: rotate(-45deg);
//  width: 6px;
//  height: 6px;
//  transition: all .3s;
//}
//.js_opened_address_list.js_insert_address_select:after{
//  transform: rotate(-225deg);
//  top: 15px;
//}
//.js_opened_address_list.js_insert_address_select:before{
//  display: none;
//}
//.js_insert_address_select > .js_row_address:last-child{
//  margin-bottom: 0;
//}
//.js_row_address .custom_radio_wrapper{
//  width: 100%;
//  height: 35px;
//}
//.checkedClass .like_a_input{
//  /*color: #c61d41;*/
//}
//.opened_collapse_block{
//  position: relative;
//  z-index: 500;
//}
//.sub_block_row.mb_nas_punkt{
//  margin-bottom: 15px;
//}
//.mb_0_imp{
//  margin-bottom: 0 !important;
//}
//.address_collection_fields input{
//  width: 100% !important;
//  display: block;
//}
//#simplecheckout_payment_form{
//  position: relative;
//  z-index: 9999999;
//}
//.mb10{
//  margin-bottom: 10px;
//}
//.hover_tooltip{
//  position: relative;
//}
//.hover_tooltip_value{
//  position: absolute;
//  top: calc(100% + 10px);
//  left: 0;
//  right: 0;
//  text-align: center;
//  transform: scale(0);
//  opacity: 0;
//  transition: all .2s;
//  background-color: white;
//  border: 1px solid #e9e9e9;
//  border-radius: 5px;
//  padding: 10px;
//  background-color: white;
//}
//.hover_tooltip:hover .hover_tooltip_value{
//  opacity: 1;
//  transform: scale(1);
//}
//.hover_tooltip:hover{
//  z-index: 50;
//  opacity: 1 !important;
//}
//.left_three_col{
//  flex-wrap: wrap;
//  width: 200px;
//}
//.left_three_col .sub_block_row{
//  width: 100%;
//}
//.mini_icon_pere{
//  width: 80px;
//  vertical-align: middle;
//  margin-right: 15px;
//}
//.right_three_col{
//  min-width: 150px;
//  justify-content: flex-start;
//}
//.three_columns_wrap{
//  justify-content: flex-start;
//  flex-wrap: wrap;
//}
//.right_three_col a{
//  margin-left: 4px;
//}
//input[name="time_delivery"][disabled="disabled"] + label{
//  cursor: not-allowed;
//}
//.disallow_message{
//  display: block;
//  font-size: 10px;
//  position: absolute;
//  top: 100%;
//  width: 100%;
//  left: 0;
//  right: 0;
//}
//.insactive_delivery_type{
//  opacity: .5;
//  cursor: default;
//}
///*.compact_right_side .pre_cart_total{
//    position: relative;
//    max-height: 0;
//    overflow: hidden;
//    transition: all .7s;
//}
//.compact_right_side .pre_cart_total:hover{
//    max-height: 500px;
//}
//.compact_right_side .pre_cart_total:hover:after{
//    top: 100%;
//}
//.compact_right_side .pre_cart_total:after{
//    display: block;
//    position: absolute;
//    left: 0;
//    right: 0;
//    bottom: 0;
//    top: 0;
//    background-image: linear-gradient(to top, rgb(252, 253, 252), transparent);
//    content: "";
//    transition: all .7s;
//}
//.compact_right_side.right_side .block{
//    padding-top: 20px;
//    padding-bottom: 20px;
//}*/
//@media (max-width: 768px){
//  #simplecheckout_customer .checkout__input-row > .checkout__input-box{
//    width: 100%;
//  }
//  #simplecheckout_customer .checkout__input-row > .checkout__input-box:first-child,
//  #simplecheckout_customer .checkout__input-row > .checkout__input-box:nth-child(2n + 1){
//    padding-right: 0;
//  }
//  #simplecheckout_customer .checkout__input-row > .checkout__input-box:nth-child(2n){
//    padding-left: 0;
//  }
//}
//@media (min-width: 768px){
//  .product__reviews {
//    font-size: 11px;
//  }
//}