/*
    Created on : 9 февр. 2020 г., 10:30:34
    Author     : Andy Kar
*/
@import "../../_variables";

#order-table-wrap{

  .red{ color: $red; }
  .green{ color: $green; }
  .text{ color: $text; }

  a{
    text-decoration: none;
    border: none;
  }

  .order-title-wrap{
    border-bottom: 1px solid #E9E9E9;
    width: 100%;

    h2{
      margin-bottom: 20px;
      line-height: 44px;
      text-transform: none;
    }
  }

  .order-table{
    .tbody, .theader{
      .tr, .th{
        box-sizing: border-box;
        width: 770px;
        max-width: 100%;
        min-height: 78px;
        padding: 20px 0;
        border-bottom: 1px solid #E9E9E9;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        .table-cell{
          flex: 1 1 auto;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;

          .order-info-title{
            font-family: Circe, sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 22px;
            color: $grey;
            min-width: 145px;
            max-width: 145px;
            padding-right: 10px;
          }

          span{
            font-family: 'Circe', sans-serif;
            font-style: normal;
            font-weight: normal;
            color: $text;
            font-size: 14px;
            line-height: 22px;
            text-align: left;
            width: 100%;

            &.order-icon{
              position: relative;
              width: 20px;
              display: inline-block;
              margin-left: 10px;

              &:hover{
                .order-icon-info{
                  display: flex;
                }
              }

              .order-icon-info{
                position: absolute;
                top: -21px;
                left: -17px;
                display: none;
                color: #4f9e34;
              }

              svg{
                fill: $green;
              }
            }
            &.order_id{
              width: max-content;
            }

            &.red{ color: $red; }
            &.green{ color: $green; }
            &.text{ color: $text; }
          }

          &.order-cell{
            width: 100px;

            .order_id{
              font-size: 20px;
              line-height: 22px;
              text-decoration-line: underline;
              color: $green;
              text-align: left;

              &.order_info{
                cursor: pointer;
              }
            }
          }

          &.card-cell{
            width: 70px;
          }

          &.product-cell{
            width: 130px;
          }

          &.status-cell{
            width: 140px;
          }

          &.payment-cell{
            width: 100px;
          }

          &.total-cell{
            width: 120px;

            .total{
              font-weight: bold;
            }
          }

          &.date-cell{
            width: 110px;

            .date_added{
              text-align: right;
            }
          }
        }
      }
    }

    .theader{
      .th{
        height: 62px;
        padding: 20px 0;

        .table-cell{
          span{
            font-weight: bold!important;
            font-size: 14px!important;
            line-height: 22px!important;
            color: $grey!important;
            text-decoration: none!important;
          }

          .date_added{
            text-align: right;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px){

  #order-table-wrap{
    .order-table .tbody .tr{
      .table-cell{
        width: 100%!important;

        &.date-cell{
          .date_added{
            text-align: left;
          }
        }
        span{
          margin-bottom: 10px;
          max-width: calc(100% - 145px);
        }
      }
    }
  }
}
