/* 
    Created on : 04.02.2020, 14:42:09
    Author     : Andy Kar
*/
@import "../../_variables";

#cashback{
				
	.summa{
		position: absolute;
		left: 0px;
		bottom: 23px;
		width: 190px;
		text-align: center;
		font-family: 'Circe', sans-serif;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		line-height: 29px;
		color: $green;
	}


	h2{
		font-family: 'Circe', sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 30px;
		line-height: 44px;
		color: $text;
		margin-bottom: 21px;
		text-transform: none;
	}

	h3{
		font-family: 'Circe', sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		line-height: 29px;
		color: $text;
		margin-bottom: 30px;

		span{
			color: $green;
			font-weight: normal;
		}

		.discount-separator{
			display: inline-block;
			width: 2px;
			height: 25px;
			background: $green;
			margin: 0 24px 0 23px;
			vertical-align: middle;
		}

		.rub{
			color: $text;
		}
	}

	p{
		font-family: 'Circe', sans-serif;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
		color: $text;
		margin-bottom: 20px;
	}

	.bonus-info-wrap{
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: flex-start;
		margin-bottom: 52px;

		.bonus-info{
			padding: 15px 20px;
			background: #FEFEFE;

			h4{
				font-family: 'Circe', sans-serif;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 20px;
				text-decoration-line: underline;
				color: $green;
				margin-bottom: 10px;

			}

			.bonus-value{
				font-family: 'Circe', sans-serif;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 29px;
				color: $text;
				margin-bottom: 0;

				.bonus-icon{
					vertical-align: middle;
					line-height: 29px;
					margin-right: 6px;
				}
			}

			&.account-awaiting-points{
				width: 210px;
				margin-right: 20px;

				.account-awaiting-points-value{
					color: $grey;
				}
			}

			&.account-points{
				max-width: 250px;
				margin-right: 30px;
				box-shadow: 0px 5px 20px rgba(156, 156, 156, 0.2);
				border-radius: 5px;
			}

		}

		.bonus-separator{
			height: 70px;
			width: 2px;
			background: #F5F5F5;
			align-self: center;
			margin-right: 20px;
		}
	}

	.bonus_visual_block{
		position: relative;
		width: 760px;
		//overflow: auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;
		margin-left: -30px;

		.bonus_visual_level{
			width: 700px;
			height: 46px;
			background-image: url(../../image/icons/bonus_bg.jpg);
			position: absolute;
			left: 30px;
			bottom: 62px;
			box-shadow: 0px 5px 20px rgba(156, 156, 156, 0.2);
			border-radius: 5px;
			overflow: hidden;
		}

		.bonus_level{
			position: relative;
			min-width: 10px;
			height: 46px;
			background-color: rgba(79, 158, 52, 0.7);
			background-image: url(../../image/icons/bonus_bg.jpg);
			background-blend-mode: multiply;

			&:after {
				content: ' ';
				height: 50px;
				width: 50px;
				display: block;
				background: linear-gradient(45deg, transparent 64%, rgba(79, 158, 52, 0.7) 64%);
				right: -20px;
				transform: scale(0.5, 1) rotate(45deg);
				position: absolute;
				top: -2px;
			}

		}

		.customer_group_item{
			position: relative;
			height: 193px;
			text-align: left;

			&.active_item{
				.drop{
					background: $green;

					.discount-value{
						color: #fff;
						opacity: 1;
					}
				}
			}
			
			&:nth-child(2){
				.drop{
					&:after, &:before{
						display: none;
					}
				}

				.discount-level-value{
					display: none;
				}
			}

			.discount-level-value{
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				text-align: center;
				font-family: 'Circe', sans-serif;
				font-style: normal;
				font-weight: bold;
				font-size: 14px;
				line-height: 21px;
				color: $text;
				opacity: 0.5;
			}

			.next_step{
				position: absolute;
				left: 0;
				bottom: 113px;
				width: 200px;
				text-align: center;
				font-family: 'Circe', sans-serif;
				font-style: normal;
				font-weight: bold;
				font-size: 12px;
				line-height: 20px;
				color: $text;
			}

			.drop{
				display: inline-block;
				width: 60px;
				height: 60px;
				background: $lite-grey;
				border-radius: 50% 50% 50% 0%;
				transform: rotate(-45deg);
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				margin-top: 10px;

				&:before{
					content: ' ';
					display: block;
					width: 10px;
					height: 10px;
					border-radius: 50%;
					background: #E9E9E9;
					position: absolute;
					left: -60px;
					bottom: -60px;

				}

				&:after{
					content: ' ';
					display: inline-block;
					width: 2px;
					height: 80px;
					background: #E9E9E9;
					position: absolute;
					left: -29px;
					bottom: -68px;
					transform: rotate(45deg);

				}

				.discount-value{
					font-family: 'Circe', sans-serif;
					font-style: normal;
					font-weight: bold;
					font-size: 20px;
					line-height: 29px;
					text-align: center;
					color: $text;
					transform: rotate(45deg);
					opacity: 0.6;
				}

			}
		}
	}
	
	.mobile-block{
		display: none;
		
		.bonus-account-info{
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 5px 0;
			
			.bonus-info-title{
				font-family: Circe, sans-serif;
				font-style: normal;
				font-weight: bold;
				font-size: 14px;
				line-height: 22px;
				color: $grey;
			}
			
			.bonus-info-value{
				font-family: Circe, sans-serif;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 21px;
				color: $text;
				
				&.green{
					color: $green;
				}
			}
		}
	}
}

#rewards-table-wrap{
	margin-top: 70px;
	
	.red{ color: $red; }
	.green{ color: $green; }
	.text{ color: $text; }
	
	a{
		text-decoration: none;
		border: none;
	}
	
	.rewards-title-wrap{
		border-bottom: 1px solid #E9E9E9;
		width: 100%;
		
		h2{
			margin-bottom: 20px;
			line-height: 44px;
			text-transform: none;
		}
	}
	
	.rewards-table{
		.tbody, .theader{
			.tr, .th{
				width: 770px;
				max-width: 100%;
				min-height: 38px;
				padding: 20px 0;
				border-bottom: 1px solid #E9E9E9;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				align-items: center;

				.table-cell{
					flex: 1 1 auto;
					display: flex;
					flex-wrap: wrap;
					justify-content: flex-start;
					
					.reward-info-title{
						font-family: Circe, sans-serif;
						font-style: normal;
						font-weight: bold;
						font-size: 14px;
						line-height: 22px;
						color: $grey;
						min-width: 145px;
						max-width: 145px;
						padding-right: 10px;
					}

					span{
						font-family: 'Circe', sans-serif;
						font-style: normal;
						font-weight: normal;
						color: $text;
						font-size: 14px;
						line-height: 22px;
						text-align: left;
						width: 100%;
						
						&.red{ color: $red; }
						&.green{ color: $green; }
						&.text{ color: $text; }
					}
					
					
					&.date-cell{
						width: 200px;
					}
					
					&.description-cell{
						width: 300px;

						.order-icon{
							width: 20px;
							display: inline-block;
							margin-left: 10px;

							svg{
								fill: $green;
							}
						}
						.description{
							width: max-content;
						}
					}
					
					&.status-cell{
						width: 170px;
					}
					
					&.total-cell{
						width: 95px;
						
						span{
							font-weight: bold;
							text-align: right;
						}
						
					}
				}
			}
		}
		
		.theader{
			.th{
				height: 22px;
				padding: 20px 0;
					
				.table-cell{
					span{
						font-weight: bold!important;
						font-size: 14px!important;
						line-height: 22px!important;
						color: $grey!important;
						text-decoration: none!important;
					}
				}
			}
		}	
	}
}

@media screen and (max-width: 1089px){
	#cashback{
		.bonus_visual_block_wrap{
			overflow: auto;
	
			.bonus_visual_block{
				margin-left: 0;
			}
		}
	}
}

@media screen and (max-width: 767px){

	#cashback{
		.bonus_visual_block_wrap{
			.bonus_visual_block{
				margin-left: -130px;
				transform: scale(0.65);
			}
		}
		
		h3{
			.discount-separator{
				display: none;
			}
			
			.ballcurs{
				display: block;
			}
		}
		
		.bonus-info-wrap{
			.bonus-info{
				max-width: 100%!important;
				width: 100%!important;
				margin-right: 0!important;
				box-shadow: none!important;
				border-radius: 0!important;
				display: flex;
				justify-content: space-between;
				padding: 10px 0;
				border-bottom: 1px solid #F5F5F5;
				
				p{
					font-size: 16px!important;
					max-width: max-content;
				}
			}
			
			.bonus-separator{
				display: none;
			}
		}
	}
	
	#rewards-table-wrap{
		.rewards-table .tbody .tr{
			.table-cell{
				width: 100%!important;
				
				&.total-cell{
					span{
						text-align: left;
					}
				}
				span{
					width: auto;
					margin-bottom: 10px;
					max-width: calc(100% - 145px);
				}
			}
		}
	}
}
