/* 
    Created on : 03.09.2019, 06:18:59
    Author     : Andy Kar
*/
@import "../../../_variables";

.Input, .form-group, {
	max-width: 370px;
	width: 100%;
	margin-bottom: 26px;
	
	&.Invalid{
		input{
			border-color: $red!important
		}
	}

	&.required label:after{
		content: '*';
		color: $red;
	}
	
	.text-danger{
		position: absolute;
		color: $red;
	}

	label{
		display: block;
		font-family: 'Circe', sans-serif;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 21px;
		color: $grey;
		margin-bottom: 5px;

	}

	input{
		width: 100%;
		display: block;
		border: 1px solid $lite-grey;
		box-sizing: border-box;
		border-radius: 5px;
		font-family: 'Circe', sans-serif;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: $text;
		text-transform: none;
		padding: 0 5px 0 15px;
		line-height: 45px;
		outline: none;

	}
	
	
}

.js_geihelper_list{
	position: relative;
	//width: 100%;
}
.geohelper_list{
	position: absolute;
	left: 0;
	background-color: white;
	list-style: none;
	max-height: 200px;
	overflow: auto;
	box-shadow: 0px 0px 6px 0px rgba(156, 156, 156, 0.2);
	border-radius: 0;
	max-width: calc(100% - 2px);
	margin: 0 1px;
	text-align: left;
	width: 100%;
	top: 45px;
	z-index: 999999;
}
.geohelper_list li{
	cursor: pointer;
	padding: 7px 15px;
	font-size: 16px;
}

@media screen and (max-width: 767px){
	.form-group{
		min-width: 100%;

		input{
			text-align: left!important;
			padding: 0 5px 0 15px!important;
		}
	}
}
