/*footer*/

.left_side .block.block_get_more_in_cart{
  margin-bottom: 0;
}
.left_side .block#simplecheckout_customer{
  margin-bottom: 35px;
}
.foot_copy{
  background-color: #fcfdfc;
  padding: 15px 0;
  text-align: center;
  font-size: 12px;
  color: #343434;
  border-top: 1px solid #eeeeee;
}
.footer{
  padding-top: 40px;
  background-color: #fcfdfc;
}
.footer > .wrapper{
  padding-bottom: 40px!important;
}
.foot_logo_link{
  max-width: 150px;
  display: block;
  position: relative;
  bottom: 5px;
  img{
    width: 100%;
    //transform: translateY(3px);
  }
}
.foot_item{
  width: 20%;
}
.foot_title{
  color: #999999;
  margin-bottom: 5px;
}
.foot_payments{
  text-align: right;
}
.foot_item_worktime{
  width: 25%;
}
.foot_item_address{
  width: 25%;
}
.foot_item_phone{
  width: 10%;
}
.foot_payments img{
  display: inline-block;
  -webkit-filter: grayscale(90%);
  filter: grayscale(90%);
  transition: all .3s;
}

.foot_payments img:hover{
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}
/*footer*/