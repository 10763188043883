/*
    Created on : 10 февр. 2020 г., 10:30:34
    Author     : Andy Kar
*/
@import "../../_variables";

#cart-table-wrap{

  a{
    text-decoration: none;
    border: none;
  }

  .product-title-wrap{
    border-bottom: 2px solid #E9E9E9;
    width: 100%;

    h2{
      margin-bottom: 20px;
      line-height: 44px;
    }
  }

  .product-cart-table{
    .tbody{
      .tr{
        width: 770px;
        max-width: 100%;
        min-height: 135px;
        padding: 20px 0;
        border-bottom: 1px solid #E9E9E9;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        &.bottom-tr{
          border-bottom: none;
          margin-top: 20px;
          margin-bottom: 40px;

          .delete-wishlist{
            text-transform: none;
            display: block;
            cursor: pointer;

            &:hover{
              span{
                color: $warning;
              }
            }
          }

          .tr-group{
            .table-cell.card-cell, .table-cell.total-cell{

              .td{
                margin-bottom: 0;
              }
            }
          }
        }

        .tr-group{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .table-cell{
            flex: 1 1 auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;

            &.remove-cell{
              width: 20px;

              .delete_button{
                cursor: pointer;
              }

              .form-close{
                display: inline-block;
                position: relative;
                width: auto;
                height: 30px;
                transform: scale(0.5);
                opacity: 0.5;
                top: 0;
                right: 0;
                vertical-align: text-top;
                margin: 1px -5px 0 0;

                &:before,&:after{
                  background-color: #333333;
                }
              }
            }

            &.image-cell{
              width: 110px;
              height: 110px;
            }

            &.name-cell{
              max-width: 240px;

              .td a{
                font-family: 'Circe', sans-serif;
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 22px;
                color: $text;
                text-decoration: none;

              }
            }

            &.quantity-cell{
              width: 200px;
              justify-content: center;

              .quantity-control{
                margin: 0 auto 8px;
              }


            }

            &.total-cell{
              width: 100px;

              .td{
                font-family: 'Circe', sans-serif;
                font-style: normal;
                font-weight: bold;
                font-size: 20px;
                line-height: 22px;
                text-align: right;
                color: $text;
                text-align: left;
                justify-content: flex-start;

                span{
                  font-weight: normal;
                  margin-left: 4px;
                }
                .pcs{
                  margin-left: 0;
                }

                &.price{
                  font-family: 'Circe', sans-serif;
                  font-style: normal;
                  font-weight: bold;
                  font-size: 12px;
                  line-height: 22px;
                  color: $text;
                  text-align: left;
                }
              }

              &.bottom-tr{
                .td{
                  margin-bottom: 0;
                }
              }
            }

            &.card-cell{
              width: 70px;

              .td{
                justify-content: flex-end;
              }

              &.bottom-tr{
                .td{
                  margin-bottom: 0;
                }
              }
            }

            .td{
              position: relative;
              flex: 1 1 auto;
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;


              .quantity-control-wrap{
                flex: 1 0;
                text-align: left;

                .quantity-control{
                  width: 130px;
                  height: 36px;
                  background-color: #F8FBF8;
                  border-radius: 18px;
                  padding: 4px 5px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  .form-group {
                    width: auto;
                    min-width: 0;
                    margin-bottom: 0;
                  }

                  span, input{
                    display: inline-block;
                  }

                  input{
                    width: 50px;
                    padding: 0;
                    text-align: center;
                    background: transparent!important;
                    border: none;
                    outline: none;
                    -webkit-box-shadow: none;
                    font-family: 'Circe', sans-serif;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 22px;

                  }

                  .minus, .plus{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 26px;
                    min-width: 26px;
                    height: 26px;
                    border-radius: 13px;
                    background-color: #fff;
                    color: $green;
                    text-align: center;
                    line-height: 32px;
                    position: relative;
                    cursor: pointer;
                    border: 1px solid #E6E6E6;
                    box-sizing: border-box;
                  }
                }
              }
            }
          }
        }
      }

      .to-cart{
        padding: 0 !important;
        margin: 0;
        width: 70px;
        height: 30px;
        border: 1px solid #E6E6E6;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .buttons-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin-top: 17px;
        margin-bottom: 40px;

        .btn-continue{
          text-transform: none;
        }
      }
    }
  }

  .continue{
    margin-top: 30px;
  }

  @media screen and (max-width: 767px){
    .product-cart-table{
      .tbody .tr .tr-group{
        .table-cell{
          &.name-cell {
            width: calc(100% - 140px);
          }

          &.remove-cell{
            order: 3;
            align-self: flex-start;
          }
          &.total-cell{
            .td{
              display: block;
            }
          }
          &.quantity-cell{
            width: 100px;
            margin-right: 10px;

            .td .quantity-control-wrap .quantity-control{
              width: 100px;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}