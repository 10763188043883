/* 
    Created on : 03.09.2019, 06:18:59
    Author     : Andy Kar
*/


@import "_fonts";
@import "_variables";
@import "_forms";
@import "_futures";
//@import "_wishlist";
//@import "_orders";
//@import "_order";
//@import "_bonus";
//@import "_preloader";

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	border: 0 none;
	font: inherit;
	font-family: 'Circe', Arial, sans-serif;
	margin: 0;
	padding: 0;
	vertical-align: baseline;
}
*, *:before, *:after {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
*, :focus {
	outline: none;
}

.body {
	width: 100%;
	margin: 0;
	min-height: 100vh;
	display: -webkit-flex;
	-webkit-flex-direction: column;
	display: flex;
	flex-direction: column;

	.header {
		width: 100%;
		-webkit-flex: 0 0 auto;
		flex: 0 0 auto;
		position: relative;
		z-index: 99;
	}

	#account-account {
		overflow: hidden;
		width: 100%;
		-webkit-flex: 1 0 auto;
		flex: 1 0 auto;
	}

	.footer {
		overflow: hidden;
		width: 100%;
		-webkit-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	//.content-columns {
	//	width: 100%;
	//	display: flex;
	//	flex-wrap: wrap;
	//	justify-content: space-between;
	//	align-items: flex-start;
	//	display: -webkit-flex;
	//	-webkit-flex-wrap: wrap;
	//	-webkit-justify-content: space-between;
	//	-webkit-align-items: flex-start;
	//
	//	.left-column {
	//		-webkit-flex: 0 0 auto;
	//		flex: 0 0 auto;
	//		width: 100%;
	//		max-width: 345px;
	//	}
	//
	//	.main-column {
	//		flex: 1 0;
	//		-webkit-flex: 1 0;
	//		max-width: 100%;
	//		min-width: 728px;
	//		//min-height: 300px;
	//	}
	//
	//	.right-column {
	//		-webkit-flex: 0 0 auto;
	//		flex: 0 0 auto;
	//		width: 100%;
	//		max-width: 345px;
	//	}
	//}

	.noselect {
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none;
		/* Non-prefixed version, currently supported by Chrome and Opera */
	}
}

#account-account{
	background: #fff;
	padding: 70px 0 130px;
	
	.wrap{
		width: 100%;
	}

	.success-text{
		color: $green;
		display: block;
		margin-bottom: 20px;
	}
	
	.container {
		padding: 0 15px;
		max-width: 1200px;
		width: 100%;
		margin: 0 auto;

		& .container {
			padding: 0;
		}

		&:after {
			content: " ";
			display: block;
			clear: both;
		}
	}
	
	.list-unstyled{
		padding: 0;
		list-style-type: none;
	}
	
	h1{
		position: fixed;
		top: -200px
	}
	
	.goback{
		margin-bottom: 15px;
		cursor: pointer;
		
		&:before {
			content: url(./image/arrow_back.svg);
			display: inline-block;
			margin-right: 10px;
			width: 17px;
			height: 12px;
			opacity: 0.5;
		}
	}
	
	.mobile-block{
		display: none;
	}
	
	.noselect {
		-webkit-touch-callout: none; /* iOS Safari */
		  -webkit-user-select: none; /* Safari */
		   -khtml-user-select: none; /* Konqueror HTML */
			 -moz-user-select: none; /* Firefox */
			  -ms-user-select: none; /* Internet Explorer/Edge */
				  user-select: none; /* Non-prefixed version, currently
										supported by Chrome and Opera */
	}
	
	p{
		padding: 0;
	}
	
	#main_content{
		max-width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;
		
		#account-menu{
			width: 270px;
			padding: 45px 0;
			background: $bg;
			box-shadow: 0px 5px 20px rgba(156, 156, 156, 0.2);
			border-radius: 5px;
			margin-bottom: 50px;
			
			li{
				
				.menu_li{
					padding: 0 40px;
					cursor: pointer;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					align-items: center;
					text-decoration: none;
					color: inherit;

					&.active{
						.menu_item{
							color: $green;
						}
					}

					.li_quantity{
						width: 20px;
						height: 20px;
						background: $green;
						border-radius: 50%;
						font-family: 'Circe', sans-serif;
						font-style: normal;
						font-weight: bold;
						font-size: 12px;
						line-height: 18px;
						display: flex;
						align-items: center;
						justify-content: center;
						text-align: center;
						color: $bg;
						line-height: 22px;
						display: inline-block;
					}

					.menu_item{
						font-family: 'Circe', sans-serif;
						font-style: normal;
						font-weight: normal;
						font-size: 16px;
						line-height: 46px;
					}

					&:hover{
						background: $bg;
						box-shadow: 0px 2px 20px rgba(156, 156, 156, 0.15);
					}
				}
			}
		}
		
		#content-wrap{
			position: relative;
			flex: 1 1 auto;
			display: flex;
			justify-content: center;
			width: 770px;
			transition: opacity 300ms ease;
			max-width: 100%;
		
			#content{
				max-width: 770px;
				width: 100%;
				transition: opacity 300ms ease;

				#main_data{
					margin-bottom: 24px;
				}

				#address_list{
					margin-bottom: 0px;
				}

				#new_address{
					margin-bottom: 44px;
				}

				#bonus_data{

				}
				
				#maininfo, #addresslist, #cashback{
					position: relative;
				}

				h2{
					font-family: 'Circe', sans-serif;
					font-style: normal;
					font-weight: bold;
					font-size: 20px;
					line-height: 29px;
					color: $text;
					margin-bottom: 15px;
				}

				h3{
					font-family: 'Circe', sans-serif;
					font-style: normal;
					font-weight: bold;
					font-size: 16px;
					line-height: 24px;
					color: $text;
					margin-bottom: 10px;
				}

				legend{
					width: 100%;
					text-align: left;
					font-family: 'Circe', sans-serif;
					font-style: normal;
					font-weight: bold;
					font-size: 30px;
					line-height: 44px;
					color: $text;
					margin-bottom: 12px;

				}

			}
		}
	}

	@media screen and (max-width: 767px){
		
		#main_content #account-menu{
			width: 100%;
		}
		
		.mobile-block{
			display: block!important;
		}
		
		.desctop-block{
			display: none!important;
		}
	}
}
