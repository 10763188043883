/* 
    Created on : 02.02.2020, 19:10:23
    Author     : Andy Kar
*/

#maininfo{
	
	.button-group{
		position: absolute;
		top: 0;
		right: 0;
		width: auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: center;

		button{
			margin-right: 20px;

			&:last-child{
				margin-right: 0;
			}
		}
	}

	#form_maininfo{
		min-height: 254px;
	}
}


