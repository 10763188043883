/* 
    Created on : 10.12.2019, 06:18:59
    Author     : Andy Kar
*/

//Color
$green: #4F9E34;
$text: #343434;
$red: #C61D41;
$grey: #999999;
$lite-grey: #E6E6E6;

$main-bg: #E5E5E5;
$bg: #FEFEFE;;

$warning: #f23010;


// Container
$container-width: 1260px;
$container-padding: 30px;
