/* 
    Created on : 30.01.2020, 13:27:50
	Author     : Andy Kar
*/
@import "../../../_variables";

button{
	padding: 14px 30px 12px!important;
	margin-bottom: 26px;
	border: 1px solid $green;
	background: transparent;
	color: $green;
	cursor: pointer;
	outline: none;
	box-shadow: none;
	
	&:focus{
		outline: none;
	}
}

.btn {
    text-align: center;
    font-size: 11px;
    line-height: 14px;
    text-transform: uppercase;
    position: relative;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    display: inline-block;
    vertical-align: top;
    font-family: ubuntu,Arial,Helvetica,sans-serif;
	cursor: pointer;
}

.btn-add{
	width: 42px;
    height: 42px;
    border-radius: 6px;
	line-height: 14px;
	color: $green;
	border: 1px solid $green;
	background: transparent;
	font-family: 'Circe', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	letter-spacing: 0.1em;
	text-transform: uppercase;
}

.to-cart, .copy_order{
	border-radius: 21px;
}
.icon-button{
	width: 42px;
    height: 42px;
	margin-bottom: 26px;
	padding: 0!important;
    border-radius: 6px;
	line-height: 14px;
	color: #fff;
	background: $green;
	border: 1px solid $green;
	font-family: 'Circe', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	letter-spacing: 0.1em;
	text-transform: uppercase;
}



.btn-save {
	font-family: 'Circe', sans-serif;
	border-radius: 22px;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	align-items: center;
	text-align: center;
	letter-spacing: 0.1em;
	text-transform: uppercase;
}

.btn-continue{
	margin-top: 30px;
	border-radius: 22px;
}

.delete_button{
	background: none;
	border: none;
	font-family: 'Circe', sans-serif;
	font-style: normal;
	text-transform: none;
	font-weight: bold;
	font-size: 16px;
	line-height: 22px;
	color: $grey;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 0;
	
	&:before{
		content: ' ';
		display: inline-block;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		border: 1px solid $lite-grey;
		margin-right: 7px;
		background-repeat: no-repeat;
		background-position: center center;
		background-color: transparent;
		background-image: url(../../../image/icons/delete.svg);
	}
}