/* 
    Created on : 02.02.2020, 14:58:01
    Author     : Andy Kar
*/

@import "../../_variables";

.address_wrap{
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	text-align: left;

	.address{
		max-width: 370px;
		width: 100%;

		input{
			border: 1px solid $lite-grey;
			box-sizing: border-box;
			border-radius: 5px;
			font-family: 'Circe', sans-serif;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			color: $text;
			text-transform: none;
			padding: 0 5px 0 15px;
			line-height: 45px;
		}

	}


	input[type="radio"]{
		display: none;
	}

	.button-group{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		max-width: 370px;
		margin-bottom: 20px;
		padding: 0 0 0 30px;
	}

	.text-default{
		font-family: 'Circe', sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		line-height: 45px;
		color: $text;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		line-height: 1.2;

		&:before{
			content: ' ';
			display: inblock;
			background-image: url(../../image/icons/gal_green.svg);
			width: 24px;
			height: 24px;
			border-radius: 50%;
			border: 1px solid #E6E6E6;
			margin-right: 7px;
			background-repeat: no-repeat;
			background-position: center center;
			background-color: transparent;
		}
	}
	.text-do-default{
		font-family: 'Circe', sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		line-height: 45px;
		color: $grey;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		cursor: pointer;
		line-height: 1.2;

		&:before{
			content: ' ';
			display: block;
			background-image: url(../../image/icons/gal.svg);
			width: 24px;
			height: 24px;
			border-radius: 50%;
			border: 1px solid #E6E6E6;
			margin-right: 7px;
			background-repeat: no-repeat;
			background-position: center center;
			background-color: transparent;
		}
	}
}
	