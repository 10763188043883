/* 
    Created on : 02.02.2020, 19:22:41
    Author     : Andy Kar
*/
#account-account{
	.new-address{
		.fieldset{
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			align-items: center;
			text-align: left;

			.form-group{
				&:nth-child(1){
					width: 180px;
				}
				&:nth-child(2){
					width: 200px;
				}
				&:nth-child(3){
					width: 100px;
				}
				&:nth-child(4){
					width: 100px;
				}

				margin-right: 20px;
			}
			
			.button-group{
				flex: 1 1 auto;
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-start;
				align-items: center;
				
				button{
					margin-right: 20px;
					
					&:last-child{
						margin-right: 0;
					}
				}
			}
		}
	}
}
@media screen and (max-width: 767px){
	.address_wrap .button-group{
		padding-left: 15px!important;
	}
	
	.delete_button{
		padding: 0 10px 0 20px!important;
	}
}
